import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";
import { logEntry } from "../../../Common/logEntry";

export async function removeFromSprint(activities,taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id,MODULE) {
 // console.log(taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id)
 if (story_type === 'group' && main_task_id !== '0') {
    Alert('warning', 'Group Task cannot be moved individually into timeline');
  } else {
    const message = `This ${MAINTASK} removed from ${MODULE} ${sprintDesc}`

    dispatch(isLoading());

    try {
      const start_time=Date.now();
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "remove_from_sprint",
        sprintId: sprintId,
        userStoryId: taskId,
        "projectId": getUser.projectId
      }, {}, false);
      const end_time=Date.now();

      if (response.status === 'True') {
        if (story_type === 'group' && main_task_id === '0') {
          getGroupTaskInfo(activities,taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id)
        }
        else{
          activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
            sub_activity:"Remove From Timeline",
            response_time : (end_time-start_time), task_meta_data:{story_id:taskId}
    
          })
        }
        addCommentUserStory(dispatch, getUser, taskId, message, "11");
        Alert("success", "UPDATED");
      }
    } catch (error) {
      Alert('error', error.message);
            //error log
            activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
              sub_activity:"Remove From Timeline",
              error_message:error.message
            })
      dispatch(isLoaded());
    }
    handleClose()
  }
}

async function getGroupTaskInfo(activities,taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id) {
  try {
    var response = await API.post("getUpdateSprint.php", {
      story_us_id: story_us_id,
      action: "get_group_task_info",
    }, {}, false);
    if (response.status === 'True') {
      // console.log(response.data, "im here")
      response.data.map(item => {
        return removeGroupTasks(activities,item.story_id, item.sprint_id, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id)
      })
    }
  } catch (error) {
    console.log(error)
  }
  handleClose()
}

async function removeGroupTasks(activities,taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id,MODULE) {
  const message = `This ${MAINTASK} removed from  ${MODULE} ${sprintDesc}`

  try {
    const start_time=Date.now();
    var response = await API.post("getUpdateSprint.php", {
      crop: getUser.corp,
      action: "remove_from_sprint",
      sprintId: sprintId,
      userStoryId: taskId,
      "projectId": getUser.projectId
    }, {}, false);
    const end_time=Date.now();
    if (response.status === 'True') {
      addCommentUserStory(dispatch, getUser, taskId, message, "11");
      activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
        sub_activity:"Remove From Timeline",
        response_time : (end_time-start_time), task_meta_data:{story_id:taskId}
      })
    }
  } catch (error) {
    Alert('error', error.message);
          //error log
          activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
            sub_activity:"Remove From Timeline",
            error_message:error.message
          })
  }
  handleClose()
}