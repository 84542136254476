//(UPDATE)
import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";
import { logEntry } from "../../../Common/logEntry";

export async function deActiveEmployee(dispatch, getUser, empId, email,userName, handleClose, handleModalClose, APP_NAME,activity_id) {
  dispatch(isLoading());
  try {
    const start_time=Date.now()
    var response = await API.post("manage_user.php", {
      "corp": getUser.corp,
      "action": 'deactivate_user',
      "empId": empId,
      url: Client_URL,
      appName: APP_NAME,
    }, {}, false);
    const end_time = Date.now()
    if (response.status === 'True') {
      Alert("success", 'Squad Member is Deactivated')
      handleClose()
      logEntry({user_id:userName,logging_level:3,activity_id:activity_id,response_time:(end_time-start_time)})
      // deActivateEmployee(dispatch, getUser, email, handleClose, handleModalClose) //De Activate the Employee -->GNK -01
      // Alert("success", 'Employee is Deleted')
      // handleClose()
    }
    else {
      handleClose()
      logEntry({user_id:userName,logging_level:2,activity_id:activity_id})
    }
  } catch (error) {
    handleClose()
    Alert('error', error.message);
    logEntry({user_id:userName,logging_level:2,activity_id:activity_id,
      error_message:error.message
    })
  }
  dispatch(isLoaded());
}