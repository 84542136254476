import { isLoaded, isLoading } from "../actions";
import { addCommentUserStory } from "./addCommentUserStory";
import Alert from "../../Alert";
import API from "../../Network/API";
import { updateRoadblockTask } from "../../../UserModule/Backlogs/Services/updateRoadblockTask";
import { logEntry } from "../../logEntry";

//For Add MainTask To Kanban
export async function addMainTaskToKanban(activities,sprintData, state, dispatch, getUser, handleClose, MAINTASK_ADD, MAINTASK, KANBAN, props) {
  dispatch(isLoading());
  if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
    if(!state.userSelected){

      Alert('warning', 'Please select Team member/Group')
      dispatch(isLoaded())
    }
    else{
      const message = `This ${MAINTASK} added in ${KANBAN}`

      try {
        const start_time= Date.now()
        var response = await API.post("manage_userstories.php", {
          crop: getUser.corp,
          module_id: sprintData.moduleId,
          action: "addMainTaskToKanban",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_to: state.userSelected,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          storyPoints: state.storySelected === "" ? '0' : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
          idea_id: state.epicSelected,
          "projectId": getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
  
        }, {}, false);
        const end_time=Date.now()
        if (response.status === 'True') {
          if (props.combine_tasks === true) {
            updateRoadblockTask(getUser, response.last_insert_id, props.data.selectedRoadblocks, props.handleClose)
          } else {
            let taskId = response.taskId;
            addCommentUserStory(dispatch, getUser, taskId, message, "11");
            Alert("success", MAINTASK_ADD);
          }
          activities && logEntry({user_id:getUser.userName,logging_level:3,
            activity_id:activities["Task Management"],sub_activity:"Create",
            response_time : (end_time-start_time), task_meta_data:{story_id:response.taskId}
          })
        }
        else {
          Alert('warning',`something went wrong, ${MAINTASK} is already exist`)
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
        activities && logEntry({user_id:getUser.userName,logging_level:2,
          activity_id:activities["Task Management"],sub_activity:"Create",
          error_message:error.message
        })
      }
      handleClose()
    }

    }
   

  else {
    Alert("warning", "Please enter required fields ")
    dispatch(isLoaded());
  }
}