import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { logEntry } from "../../../Common/logEntry";


export async function addEpic(activities,taskId, state, dispatch, getUser, handleClose, projectId) {
  dispatch(isLoading());
  if (state.sprintSelected !== "") {
    try {
      const start_time=Date.now();
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "epic_update",
        epicId: state.epicSelected.value,
        updatedBy: getUser.empId,
        userStoryId: taskId,
        "projectId": projectId || getUser.projectId
      }, {}, false);
      const end_time=Date.now();
      if (response.status === 'True') {
        activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
          sub_activity:"Add To Module",
          response_time : (end_time-start_time), task_meta_data:{story_id:taskId}
  
        })
        Alert("success", "UPDATED");
      }
    } catch (error) {
      Alert('error', error.message);
            //error log
            activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
              sub_activity:"Add To Module",
              error_message:error.message
            })
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "Something went wrong")
  }
}
