import { isLoaded, isLoading, } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addTasksComment } from "./addTasksComment";
import { logEntry } from "../../../Common/logEntry";


export async function updateSetDone(activities,dispatch, getUser, subtaskId, mainTaskId, handleClose, message) {
  dispatch(isLoading());
  try {
    const start_time=Date.now();
    var response = await API.post("get_tasks.php", {
      crop: getUser.corp,
      action: 'setSubtaskDone',
      task_id: subtaskId,
      maintaskId: mainTaskId,
      "projectId": getUser.projectId
    }, {}, false);
    const end_time=Date.now();
    if (response.status === 'True') {
      addTasksComment(dispatch, getUser, subtaskId, "2", message);
      activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
        sub_activity:"Done",
        response_time : (end_time-start_time), task_meta_data:{story_id:mainTaskId}

      })
      Alert("success", 'status changed')
      handleClose()
    } else if (response.status === "false") {
      handleClose()
      Alert("warning", response.message)
    }
  } catch (error) {
    Alert('error', error.message);
          //error log
          activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
            sub_activity:"Done",
            error_message:error.message
          })
    handleClose()
  }
  dispatch(isLoaded());
}




