import Alert from "../../../Common/Alert";
import { logEntry } from "../../../Common/logEntry";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading } from "../actions";
import { addCommentUserStory } from "./addCommentUserStory";
import { getCurrentSprintUserStoriesToKanban } from "./getCurrentSprintUserStoriesToKanban";


export async function getActiveToKanban(
  dispatch,
  getUser,
  story_id,
  moduleId,
  handleClose,
  handleOpen,
  message,
  project_name = null,
  activities
) {
  dispatch(isLoading());
  try {
    const start_time=Date.now()
    var response = await API.post(
      "manage_userstories.php",
      {
        crop: getUser.corp,
        story_id: story_id,
        empId: getUser.empId,
        action: "activate_user_story",
      },
      {},
      false
    );
    const end_time=Date.now()
    if (response.status === "true") {
      getCurrentSprintUserStoriesToKanban(dispatch, getUser, moduleId);
      addCommentUserStory(dispatch, getUser, story_id, message, "1");
      handleClose();
      activities && logEntry({user_id:getUser.userName,logging_level:3,
        activity_id:activities["Task Management"],sub_activity:"In Progress",
        response_time : (end_time-start_time), task_meta_data:{story_id:story_id}
      })
    } else if (response.status === "true1") {
      handleClose();
      const data = { inprogressStoryId: response.data, todoStoryId: story_id, currentSprint: moduleId, assignedTo: response.assignedTo, projectName: response.project_name, story_title: response.story_title, inprogress_story_title: response.inprogress_story_title, us_id: response.us_id, todo_us_id: response.todo_us_id, toDoProjectName: project_name, task_type: 'main_task', inprogress_task_type: response.inprogress_task_type }
      handleOpen("changeUserstoryStatus", data)

    } else {
      Alert("warning", response.message);

    }
  } catch (error) {
    activities && logEntry({user_id:getUser.userName,logging_level:2,
      activity_id:activities["Task Management"],sub_activity:"In Progress",
      error_message:error.message, task_meta_data:{story_id:story_id}
    })
    console.log(error);
  }

  dispatch(isLoaded());

}