import Alert from "../../../Common/Alert";
import { logEntry } from "../../../Common/logEntry";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading } from "../actions";
import { addCommentUserStory } from "./addCommentUserStory";
import { getCurrentSprintUserStoriesToKanban } from "./getCurrentSprintUserStoriesToKanban";

//Update the Task Status

async function update(
  dispatch,
  getUser,
  userStoryId,
  sprintId,
  handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING,activities
) {
  dispatch(isLoading());

  try {
    const start_time=Date.now()
    const response = await API.post(
      "manage_userstories.php",
      {
        action: "update",
        task_id: userStoryId,
        crop: getUser.corp,
        task_status: 100,
        task_status_desc: "completed",
        task_complete_status: 1,
        empId: getUser.empId,
        projectId: getUser.projectId,
      },
      {},
      false
    );
    const end_time=Date.now()
    if (response.status === "True") {
      getCurrentSprintUserStoriesToKanban(dispatch, getUser, sprintId);
      addCommentUserStory(dispatch, getUser, userStoryId, message, "2");
      activities && logEntry({user_id:getUser.userName,logging_level:3,
        activity_id:activities["Task Management"],sub_activity:"Done",
        response_time : (end_time-start_time), task_meta_data:{story_id:userStoryId}
      })
    } else {
      const warning = response.type === "tasks" ? TASKS_WARNING : ROADBLOCK_WARNING;
      Alert('warning', warning)
    }
  } catch (error) {
    Alert("error", error.message);
    activities && logEntry({user_id:getUser.userName,logging_level:2,
      activity_id:activities["Task Management"],sub_activity:"Done",
      error_message:error.message, task_meta_data:{story_id:userStoryId}
    })
  }
  dispatch(isLoaded());
  handleClose();
}

async function updateRoadblock(
  dispatch,
  getUser,
  userStoryId,
  sprintId,
  handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING
) {
  dispatch(isLoading());

  try {
    const response = await API.post(
      "manage_userstories.php",
      {
        action: "updateRoadblock",
        task_id: userStoryId,
        crop: getUser.corp,
        task_status: 100,
        task_status_desc: "completed",
        task_complete_status: 1,
        empId: getUser.empId,
        projectId: getUser.projectId,
      },
      {},
      false
    );
    if (response.status === "True") {
      getCurrentSprintUserStoriesToKanban(dispatch, getUser, sprintId);
      addCommentUserStory(dispatch, getUser, userStoryId, message, "2");
    } else {
      const warning = response.type === "tasks" ? TASKS_WARNING : ROADBLOCK_WARNING;
      Alert('warning', warning)
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
  handleClose();
}

export async function updateUserStoryToKanban(
  dispatch,
  getUser,
  userStoryId,
  sprintId,
  handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING,activities
) {
  
  try {
    const response = await API.post(
      "getUpdateSprint.php",
      {
        action: "getTaskInfoWithId",
        story_us_id: userStoryId,
        crop: getUser.corp,
      },
      {},
      false
    );
    if (response.status === "True") {
      callToCheckRoadblock(response.data[0].us_id, dispatch,
        getUser,
        userStoryId,
        sprintId,
        handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING,activities)
    } else {

    }
  } catch (error) {
    console.log("error", error.message);
  }
}

export async function callToCheckRoadblock(
  id, dispatch,
  getUser,
  userStoryId,
  sprintId,
  handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING,activities
) {

  try {
    const response = await API.post(
      "getUpdateSprint.php",
      {
        action: "getTaskInfoWithRoadblock",
        story_us_id: id,
        crop: getUser.corp,
      },
      {},
      false
    );
    if (response.status === "True") {
      if (response.message === 'success') {
        response.data.map(item => {
          return updateRoadblock(dispatch,
            getUser,
            item.story_id,
            sprintId,
            handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING)
        })
        update(dispatch,
          getUser,
          userStoryId,
          sprintId,
          handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, activities)
      } else {
        update(dispatch,
          getUser,
          userStoryId,
          sprintId,
          handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, activities)
      }
    }
  } catch (error) {
    console.log("error", error.message);
  }

}
