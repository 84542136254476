import { isLoading, isLoaded,redirect} from "./actions";
import Alert from "../../Common/Alert";
import API from "../../Common/Network/API";
import { logEntry } from "../../Common/logEntry";

export async function updatePassword(state, dispatch, getUser,activity_id) {
  dispatch(isLoading());
  if( state.newpassword.value !=="" && state.confirmpassword.value !== "" && state.currentpassword.value !== ""){
  try {
    const start_time=Date.now()
    if (state.newpassword.value.length >= 5 && state.newpassword.value === state.confirmpassword.value) {
      var response = await API.post("authentication.php", {
        oldPassword: state.currentpassword.value,
        newPassword: state.newpassword.value,
        username:getUser.userName,
        action:"change_password"
      }, {}, false);
      const end_time=Date.now()
      if(response.status === "True"){
        dispatch(redirect("passwordredirect"))
        Alert("success",response.message)
        logEntry({user_id:getUser.userName,logging_level:2,
          activity_id:activity_id,response_time:(end_time-start_time)
        })
      }else{
        Alert("warning",response.message)
        logEntry({user_id:getUser.userName,logging_level:2,
          activity_id:activity_id
        })
      }
     
    }
    else {
      Alert("warning","Both passwords must be same ");
    }
  } catch (err) {
    Alert("error",err.message);
    logEntry({user_id:getUser.userName,logging_level:2,
      activity_id:activity_id,error_message:err.message
    })
  }}else{
    Alert("warning","Fill All Fields")
  }

  dispatch(isLoaded());

}