// userprofile/services/createprivateToDo.js (CREATE)

import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { logEntry } from "../../logEntry";
import { isLoading, isLoaded } from "../actions";

export async function createPrivateToDo(activities, state, dispatch, getUser, handleClose, MAINTASK) {
  dispatch(isLoading());
  if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.storySelected !== 0 && state.prioritySelected !== 0) {
    try {
      const start_time = Date.now();
      var response = await API.post("personal_tasks.php", {
        crop: getUser.corp,
        action: "add",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        added_to: state.userSelected || getUser.empId,
        added_by: getUser.empId,
        acceptanceCriteria: state.acceptanceCriteria.value,
        storyPoints: state.storySelected,
        priorityLevel: state.prioritySelected,
        message: `This private ${MAINTASK} is added/created`
      }, {}, false);
      const end_time = Date.now();
      if (response.status === 'True') {
        Alert("success", `${MAINTASK} added successfully`);
        activities && logEntry({
          user_id: getUser.userName, logging_level: 3, activity_id: activities["Task Management"],
          sub_activity: "Create",
          response_time: (end_time - start_time),
        })
      }
    } catch (error) {
      Alert('error', error.message);
      //error log
      activities && logEntry({
        user_id: getUser.userName, logging_level: 2, activity_id: activities["Task Management"],
        sub_activity: "Create",
        error_message: error.message
      })
      dispatch(isLoaded());
    }
    handleClose()
  }
  else {
    Alert("warning", "Please enter required fields ")
    dispatch(isLoaded());
  }
}