import React from 'react'
import { useState,useEffect } from 'react';
import ReportsAPI from '../../Common/AgileNetwork/ReportsAPI';
//import { Reports_URL } from '../../Common/config';

import { Modal } from 'react-bootstrap';
import { useWebProperties } from '../../Common/webProperties';
import moment from 'moment';
import Select from 'react-select'
import RootLoader from '../../Common/Loader/RootLoader';
import { useSelector } from 'react-redux';
import { startOfWeek, endOfWeek } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import { getDecimalHours } from '../../Common/convertDecimalHoursToTime';
import API from '../../Common/Network/API';
import { removeDuplicatesFromString } from '../../Common/commonUsage';
import UserProjectInfo from './userProjectInfo';


import { Link } from 'react-router-dom';
import { logEntry } from '../../Common/logEntry';

const UserCosting = ({ActiveC}) => {
    // const [data, setData] = useState([]);
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [open, setOpen] = useState({ status: false, index: 0 })

    const [info, setInfo] = useState();
    const [loader, setLoader] = useState(false);



    const { SELECT_DATE_RANGE,  NO_OF_PROJECTS_INVOLVED,
      DATE, EMPLOYEE_NAME, ALL_EMPLOYEE_REPORTS, TOTAL_HOURS,
      } = useWebProperties();

      const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }
    const properties = useSelector(state => state.landingReducer.properties)
  
    const [filter, setFilter] = useState([
        {
            startDate: startOfWeek(new Date(), { weekStartsOn: 0 }),
            endDate: endOfWeek(new Date(), { weekEndsOn: 7 }),
            key: 'selection'
        }
    ])

    const [result, setResult] = useState([])
    const getUser = useSelector(state => state.auth)
    const [filterData, setFilterData] = useState([])
    const [searchFilterData, setSearchFilterData] = useState([]);
    const [groupDetails, setGroupDetails] = useState([])
    const [selectedGroupDetails, setSelectedGroupDetails] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const activities = useSelector(state => state.landingReducer.userActivities)
    useEffect(() => {
        getAdminGroupDetails(getUser.user)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
      const sortData = (data) => {
          return data.slice().sort((a, b) => {
              if (sortColumn === 'name') {
                  const nameA = a.name.toLowerCase();
                  const nameB = b.name.toLowerCase();
                  return sortDirection === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
              } else if (['Total_No_of_Projects', 'Total_No_of_Tasks', 'Total_No_of_Hours_Worked',].includes(sortColumn)) {
                  const a1 = parseFloat(a.data[0][sortColumn]);
                  const b1 = parseFloat(b.data[0][sortColumn]);
                  return sortDirection === 'asc' ? a1 - b1 : b1 - a1;
              }
              return 0;
          });
      };
      if (searchQuery.length > 0) {
          setSearchFilterData(prevData => sortData(prevData));
      } else {
          setFilterData(prevData => sortData(prevData));
      }
      // eslint-disable-next-line
  }, [sortColumn, sortDirection]);

  const filterGroup = (data, groupDetails) => {
      let emailArray = groupDetails.emails.split(',')

      return data.filter(obj => emailArray.includes(obj.email))
  }



  const searchFilter = (data, searchData) => {
      const filteredData = data.filter(item =>
          item.name.toLowerCase().includes(searchData.toLowerCase()) ||
          item.data[0].Total_No_of_Projects.toString().toLowerCase().includes(searchData.toLowerCase()) ||
          (item.data[0].Total_No_of_Tasks !== null && getDecimalHours(item.data[0].Total_No_of_Tasks).toString().toLowerCase().includes(searchData.toLowerCase())) ||
          (item.data[0].Total_No_of_Hours_Worked !== null && item.data[0].Total_No_of_Hours_Worked.toString().toLowerCase().includes(searchData.toLowerCase()))
      
        );
      return filteredData;
  }
  useEffect(() => {
    if (selectedGroupDetails.value !== 'All' && selectedGroupDetails.length !== 0) {
        let filteredData = filterGroup(result, selectedGroupDetails);

        setFilterData(filteredData);
        if (searchQuery.length > 0) setSearchFilterData(searchFilter(filteredData, searchQuery));

    }
    else {
        setFilterData(result);
        if (searchQuery.length > 0) setSearchFilterData(searchFilter(result, searchQuery));
    }
    // eslint-disable-next-line
}, [result]);

const handleOpen = (index, action, task_status, column_name) => {
  setOpen({ status: true, index: index, action: action });
  var info = {};
  let dateFilter = [{
      "startDate": moment(filter[0].startDate).format("YYYY-MM-DD"),
      "endDate": moment(filter[0].endDate).format("YYYY-MM-DD"),
      "key": "selection"
  }]
  if (action === "costingProject") {
      info = {
          user_id: searchQuery.length === 0 ? filterData[index].emp_id : searchFilterData[index].emp_id,
          action: 'costingProject',
          name: searchQuery.length === 0 ? filterData[index].name : searchFilterData[index].name,
        //   account_id:extension === 'agile24x7.com' ? "" : getUser.user.account_id,
          dateFilter,
          column_name,
          pay_rate: searchQuery.length === 0 ? filterData[index].pay_rate : searchFilterData[index].pay_rate
      }
  }

//   get total tasks
if (action === "totalTasks") {
    info = {
        user_id: searchQuery.length === 0 ? filterData[index].emp_id : searchFilterData[index].emp_id,
        action: 'totalTasks',
        name: searchQuery.length === 0 ? filterData[index].name : searchFilterData[index].name,
      //   account_id:extension === 'agile24x7.com' ? "" : getUser.user.account_id,
        dateFilter,
        column_name,
         pay_rate: searchQuery.length === 0 ? filterData[index].pay_rate : searchFilterData[index].pay_rate
    }
}

  setInfo(info);
};

const handleSort = (column) => {
  if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  } else {
      setSortColumn(column);
      setSortDirection('asc');
  }
};

const getSortIcon = (column) => {
  return (
      <>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
          </svg>
      </>
  );
};

const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSearchFilterData(searchFilter(filterData, event.target.value));
};
async function getAdminGroupDetails(getUser) {
  try {
      var response = await API.post("group_chat.php", {
          action: 'getAdminGroupDetails',
          created_by: getUser.userName,
          projectId: getUser.projectId,
          "userType": getUser.role,
          "empId": getUser.empId,
      }, {}, false);
      if (response.status === 'True') {
          setGroupDetails(response.data);
      }
      else {
          setGroupDetails([])
      }
  } catch (error) {
      console.log('error', error.message);
  }
}
const [, extension] = (getUser.user.userName).split('@')
async function Network(api, state, filter) {
  setResult([])
  setLoader(true)
  try {
      const start_time = Date.now()
      const response = await ReportsAPI.post(`/${api}`, 
          { filter,account_id:extension === 'agile24x7.com' ? "" : getUser.user.account_id }, {}, false)
      const end_time = Date.now()

      activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Admin"],sub_activity:"UserCosting",response_time:(end_time-start_time)})

      await state(response)
      setLoader(false)
  }
  catch (error) {
      setLoader(false)
      setMessage(error.message)
      activities && logEntry({user_id:getUser.user.userName,logging_level:2,activity_id:activities["Admin"],sub_activity:"UserCosting",error_message:error.message})
//console.log('error', error.message)
  }
}

const getCosting = () => {
  // eslint-disable-next-line 
  handleClose()
  let dateFilter = [{
      "startDate": moment(filter[0].startDate).format("YYYY-MM-DD"),
      "endDate": moment(filter[0].endDate).format("YYYY-MM-DD"),
      "key": "selection"
  }]
  Network('getCostingData', setResult, dateFilter)
}
useEffect(() => {
    getCosting()
  // eslint-disable-next-line
}, [])

const [message, setMessage] = useState("")

const handleSelectedGroups = (selectedOption) => {
  //onsole.log(selectedOption);
  if (selectedOption.value === 'All') {
      setFilterData(result)
  } else {
      setFilterData(filterGroup(result, selectedOption))
  }
  setSearchQuery('');

}

  return (
    <div>
         <div className="mt-2">
 <div className="col-lg-12 grid-margin stretch-card">
     <div className="card">
         <div className="card-body">
             <h4 className="card-title">{ALL_EMPLOYEE_REPORTS}</h4>
             <div className="d-flex justify-content-between row">
                 <div className="d-flex" >

                 {
                         <button className="btn btn-success p-2 border" style={{ marginLeft: 10 }} onClick={handleShow}>{SELECT_DATE_RANGE}</button>
                     }
                     {
                         <div style={{ marginTop: 5 }}>
                             <text style={{ fontSize: 14, fontWeight: 'bold', paddingLeft: 10 }} className=' text-success'> {DATE}: {(moment(filter[0].startDate).format('ll'))} - {(moment(filter[0].endDate).format('ll'))} </text>

                         </div>
                     }
                 </div>
                 <div className="d-flex col-lg-4 col-sm-6" >
                     <label className='mr-2 mb-0 mt-2' htmlFor='groupSelect'>Group:</label>
                     <Select
                         className="form-control"
                         placeholder="Select Group"
                         options={[
                             { 'value': 'All', 'label': 'All' },
                             ...(groupDetails?.map(group => {
                                 return {
                                     'value': group.id,
                                     'label': `${group.group_name}-[${removeDuplicatesFromString(group.members_name)}]`,
                                     'emails': group.members_email
                                 };
                             }))
                         ]}
                         onChange={(selectedOption) => {
                          handleSelectedGroups(selectedOption)
                             setSelectedGroupDetails(selectedOption)
                         }}
                     />
                 </div>
                 {/* <CSVLink className="downloadbtn btn btn-primary " filename={`AllReports-${new Date().getFullYear()}.csv`} data={csvData}>Export to CSV</CSVLink> */}


 {/* search */}
 <div className="col-12 " >
                 <Modal style={{ textAlign: 'center' }} size="xl" show={show} onHide={handleClose}>
                     <Modal.Header closeButton>
                     </Modal.Header>
                     <Modal.Body>
                         <DateRangePicker
                             onChange={item => setFilter([item.selection])}
                             showSelectionPreview={true}
                             moveRangeOnFirstSelection={false}
                             months={2}
                             ranges={filter}
                             direction="horizontal"
                         />
                     </Modal.Body>
                     <Modal.Footer>
                         <button className="btn btn-success" type="button" onClick={() => {
                             getCosting()

                         }}>Search</button>
                     </Modal.Footer>
                 </Modal>

             </div>
             {
             message && (<><br /><br /><span style={{ color: 'red', marginLeft: '10%' }}>{message}</span></>)
         }
          



             </div>
             <div className="d-flex align-items-center justify-content-between mt-1">
<div className="me-auto">
<div className="d-flex align-items-center mt-1">
<button
className={`btn me-2 ${!ActiveC  ? 'btn-success' : 'text-success border border-success'}`}

onClick={ActiveC}
>
User Report
</button>
<button
className={`btn ${ActiveC ? 'btn-success ' : 'text-success border border-success'}`}
>
User Costing
</button>
</div>
</div>
<div>
<label className="me-2 mb-0" htmlFor="searchInput">Search:</label>
<input
type="text"
className="form-control d-inline-block"
style={{ height: '30px', width: '200px' }}
aria-label="Search"
aria-describedby="basic-addon2"
onChange={handleSearch}
value={searchQuery}
/>
</div>
</div>

   {/* table */}
             <div className='p-2'>
             <div className='pt-2' />
             {result.length !== 0 ?
                 <div className="table-responsive">
                     <table
                         search="true"
                         id="example" className="table table-striped table-bordered"
                         data-pagination="true"
                     >
                         <thead style={{ backgroundColor: '#F4FAF7' }}>
                             <tr>
                              {/* 1.emp name */}
                                 <th className="text-capitalize" onClick={() => handleSort('name')}>{properties?.EMPLOYEE_NAME || EMPLOYEE_NAME} {getSortIcon('name')}</th>
                              {/*2. Total no.of projects */}
                              <th onClick={() => handleSort('projects_involved')}>Total No of Projects</th>

                              {/* 3.total no.of tasks */}
                              <th> Total No of Tasks</th>
                              {/* 4.total no of hours worked */}
                                 <th onClick={() => handleSort('working_hours')}>{properties?.TOTAL_HOURS || TOTAL_HOURS} {getSortIcon('total_hours')}</th>
                               
                               {/* 5.pay rate */}
                               <th>Pay Rate ($) </th>

                              {/*6. net Income */}
                               <th>Total Paid ($)</th>
                                            
                                 {/* {extension === 'agile24x7.com' && <th onClick={() => handleSort('no_of_accounts')}>{properties?.NO_OF_ACCOUNTS || NO_OF_ACCOUNTS} {getSortIcon('no_of_accounts')}</th>} */}

                             </tr>
                         </thead>
                         <tbody>
                             {
                                 (searchQuery.length === 0) ? filterData.map((x, index) => {
                               
                                     return (
                                         <tr key={index}>
                                             <td style={{ height: 40, fontWeight: 'bold', color: 'green', cursor: 'pointer' }}><Link style={{ color: 'green' }} >{x.name} </Link></td>
                                            
                                             <td style={{ height: 40 }} onClick={() => handleOpen(index, "costingProject", "", properties?.NO_OF_PROJECTS_INVOLVED || NO_OF_PROJECTS_INVOLVED)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].Total_No_of_Projects}</p></td>
                                            
                                            {/* {extension === 'agile24x7.com' && <td style={{ height: 40 }} onClick={() => handleOpen(index, "getUserAccounts", "", properties?.NO_OF_ACCOUNTS || NO_OF_ACCOUNTS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].no_of_accounts}</p></td>} */}
                                             
                                            <td style={{ height: 40 }} onClick={() => handleOpen(index, "totalTasks", "")}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].Total_No_of_Tasks}</p></td>

                                             <td><p>{x.data[0].Total_No_of_Hours_Worked !== null ? getDecimalHours(x.data[0].Total_No_of_Hours_Worked) : 0}</p></td>

                                              
                                             <td>{x.pay_rate}</td>
                                             <td>{ (x.data[0].Total_No_of_Hours_Worked !== null && x.pay_rate !==0) ? (`${((x.data[0].Total_No_of_Hours_Worked) * x.pay_rate).toFixed(2)}`):"0"}</td>
                                        
                                       
                                         </tr>
                                     )
                                 }) : (
                                     searchFilterData.map((x, index) => {
                                         return (
                                             <tr key={index}>
                                                 <td style={{ height: 40, fontWeight: 'bold', color: 'green', cursor: 'pointer' }}>{x.name}</td>
                                                 <td style={{ height: 40 }} onClick={() => handleOpen(index, "costingProject", "", properties?.NO_OF_PROJECTS_INVOLVED || NO_OF_PROJECTS_INVOLVED)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].Total_No_of_Projects}</p></td>

                                                 
                                                 <td onClick={() => handleOpen(index, "totalTasks", '',"Total No of Tasks")}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].Total_No_of_Tasks}</p></td>
                                                 {/* {extension === 'agile24x7.com' && <td style={{ height: 40 }} onClick={() => handleOpen(index, "getUserAccounts", "", properties?.NO_OF_ACCOUNTS || NO_OF_ACCOUNTS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].no_of_accounts}</p></td>} */}
                                                
                                                 <td><p >{x.data[0].Total_No_of_Hours_Worked !== null ? getDecimalHours(x.data[0].Total_No_of_Hours_Worked) : 0}</p></td>
                                                
                                                <td>{x.pay_rate}</td>
                                                <td>{ (x.data[0].Total_No_of_Hours_Worked !== null && x.pay_rate !==0) ? (`${((x.data[0].Total_No_of_Hours_Worked) * x.pay_rate).toFixed(2)}`):"0"}</td>


                                                
                                             </tr>
                                         )
                                     })
                                 )}
                         </tbody>
                     </table>
                 </div>
                 : loader ?
                     <RootLoader /> :
                     null}
             {
                 (open.action === "costingProject" || open.action ==='totalTasks') ? <UserProjectInfo open={open.status} data={info} handleClose={handleClose} handleModalClose={handleModalClose} 
                 /> : null
             }
         </div>

             {/*  */}
 </div>
</div>
             </div>
               
               </div>
     
    </div>
  )
}

export default UserCosting
