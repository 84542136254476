import React, { useEffect, useState } from 'react'
import AdminTopNav from '../Utility/TopNav'
import AdminSideBar from '../Utility/SideNav'
import { useWebProperties } from '../../Common/webProperties';
import { Modal } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import { startOfWeek, endOfWeek } from "date-fns";
import moment from "moment";
import Select from 'react-select'
import ReportsAPI from '../../Common/AgileNetwork/ReportsAPI';
import RootLoader from '../../Common/Loader/RootLoader';
import { CSVLink } from 'react-csv';

const EmployeeReports = () => {
    const { SELECT_DATE_RANGE, DATE } = useWebProperties();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [selectLoader,setSelectLoader] = useState(false)
    const [data, setData] = useState([]);
    const [message, setMessage] = useState("")
    const {PENDING_SUBTASKS, MODULE} = useWebProperties()
    const [dropDownData, setDropDownData] = useState({
        projectsData: [],
        sprintsData: []
    })
    const [selectedData, setSelectedData] = useState({
        projectId: '',
        sprintId: ''
    })
    const [filter, setFilter] = useState([
        {
            startDate: startOfWeek(new Date(), { weekStartsOn: 0 }),
            endDate: endOfWeek(new Date(), { weekEndsOn: 7 }),
            key: "selection",
        },
    ]);
    const [reportType, setReportType] = useState('getBacklogsReport')
    const [columns, setColumns] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        getProjectsData()
        // eslint-disable-next-line
    }, [])


    const getProjectsData = async () => {
        try {
            setSelectLoader(true)
            setMessage('')
            let projects = []
            const projectData = await ReportsAPI.get('/getProjects')
            projectData.map((project) => projects.push({ 'value': project.project_id, 'label': project.project_name }))
            setDropDownData({
                ...dropDownData,
                projectsData: projects
            })
            setSelectLoader(false)
        }

        catch (error) {
            setSelectLoader(false)
            setMessage(error.message)
            console.log('error', error.message);
        }

    }

    const getSprintsData = async (project_id) => {
        try {
            setSelectLoader(true)
            setMessage('')
            let sprints = []
            const sprintsData = await ReportsAPI.post('/sprints', { project_id: project_id })
            sprintsData.map((sprint) => sprints.push({ value: sprint.sprint_id, label: sprint.sprint }))
            setDropDownData({
                ...dropDownData,
                sprintsData: sprints
            })
            setSelectLoader(false)
        } catch (error) {
            setSelectLoader(false)
            setMessage(error.message)
            console.log('error', error.message);
        }
    }
    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearch = debounce((value) => {
        if (value.length > 0) {
            const filtered = data.filter(record =>
                (record["Task ID"] && record["Task ID"].toLowerCase().includes(value.toLowerCase())) ||
                (record["Task Description"] && record["Task Description"].toLowerCase().includes(value.toLowerCase())) ||
                (record["Assigned To"] && record["Assigned To"].toLowerCase().includes(value.toLowerCase())) ||
                (record["Developer"] && record["Developer"].toLowerCase().includes(value.toLowerCase())) ||
                (record["Assigned By"] && record["Assigned By"].toLowerCase().includes(value.toLowerCase())) ||
                (record.module && record.module.toLowerCase().includes(value.toLowerCase()))
            )
            setFilteredData(filtered)
        }
        else {
            setFilteredData(data)
        }
    }, 1000);
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        debouncedSearch(event.target.value)
    };
    const handleSubmit = async () => {
        try {
            setLoader(true)
            setData([])
            setFilteredData([])
            setMessage('')
            const result = await ReportsAPI.post(`/${reportType}`,
                { filter, project_id: selectedData.projectId, sprint_id:selectedData.sprintId }
            )
            setData(result)
            setFilteredData(result)
            if (result.length > 0) {
                const keys = Object.keys(result[0])
                setColumns(keys)
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            setMessage(error.message)
            setData([])
            setFilteredData([])
            console.log('error', error.message);
        }
    }

    const handleSelect = (selectedOption) => {
        let api = ''
        switch (selectedOption.value) {
            case 'backlog':
                api = 'getBacklogsReport'
                break
            case 'sprint':
                api = 'getSprintReport'
                break
            default:
                api = ''
                break;
        }
        setReportType(api)
    }

    const csv_data = [
        columns,
        ...filteredData.map((record) => {
            return columns.map((column) => record[column] ? record[column] : '-')
        })
    ]
    return (
        <div className="container-scroller">
            <AdminTopNav />
            <div className="container-fluid page-body-wrapper">
                <AdminSideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">All Reports</h4>
                                    <div className="">
                                        <div className="d-flex">
                                            {
                                                <button
                                                    className="btn btn-success p-2 border"
                                                    style={{ marginLeft: 10 }}
                                                    onClick={handleShow}
                                                >
                                                    {SELECT_DATE_RANGE}
                                                </button>
                                            }
                                            {
                                                <div style={{ marginTop: 5 }}>
                                                    <text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: "bold",
                                                            paddingLeft: 10,
                                                        }}
                                                        className=" text-success"
                                                    >
                                                        {" "}
                                                        {DATE}: {moment(filter[0].startDate).format("ll")} -{" "}
                                                        {moment(filter[0].endDate).format("ll")}{" "}
                                                    </text>
                                                </div>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-between row mb-2 p-2 align-items-center">
                                            <div className="d-flex col-lg-3 col-sm-6 mt-2" >
                                                <label className='mr-1 mb-0 mt-2' htmlFor='groupSelect'>Report:</label>
                                                <div className="col-lg-8 col-sm-6">
                                                    <Select
                                                        className=""
                                                        options={[
                                                            { 'value': 'backlog', 'label': `${PENDING_SUBTASKS} Report` },
                                                            { 'value': 'sprint', 'label': `${MODULE} Report` }
                                                        ]}
                                                        defaultValue={{ 'value': 'backlog', 'label': `${PENDING_SUBTASKS} Report` }}
                                                        onChange={(selectedOption) => {
                                                            handleSelect(selectedOption)
                                                            if (selectedOption.value === 'sprint' && selectedData.projectId !== '') getSprintsData(selectedData.projectId)

                                                        }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex col-lg-3 col-sm-6 mt-2" >
                                                <label className='mr-1 mb-0 mt-2' htmlFor='groupSelect'>Project:</label>
                                                <div className="col-lg-8 col-sm-6">
                                                    <Select
                                                        className=""
                                                        options={dropDownData.projectsData}
                                                        placeholder='Select Project'
                                                        onChange={(selectedOption) => {
                                                            setSelectedData({
                                                                ...selectedData,
                                                                projectId: selectedOption.value
                                                            })
                                                            if (reportType === 'getSprintReport') getSprintsData(selectedOption.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {reportType === 'getSprintReport' && (<div className="d-flex col-lg-3 col-sm-6 mt-2" >
                                                <label className='mr-1 mb-0 mt-2' htmlFor='groupSelect'>{`${MODULE}:`}</label>
                                                <div className="col-lg-8 col-sm-6">
                                                    <Select
                                                        className=""
                                                        options={dropDownData.sprintsData}
                                                        placeholder={`Select ${MODULE}`}
                                                        isDisabled={selectedData.projectId === ''}
                                                        onChange={(selectedOption) => {
                                                            setSelectedData({
                                                                ...selectedData,
                                                                sprintId: selectedOption.value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>)}
                                            <button
                                                className="btn btn-primary mr-3 mt-1" style={{ height: '38px' }} type="button"
                                                onClick={handleSubmit}
                                                disabled={selectedData.projectId==='' || (reportType==='getSprintReport' && selectedData.sprintId==='')}
                                            >Submit</button>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between row ml-2 align-items-center'>
                                        <div
                                            className="mt-1 pt"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginRight: "3%"

                                            }}
                                        >
                                            <label className="mr-2 mb-0" htmlFor="searchInput">
                                                Search:
                                            </label>
                                            <input
                                                type="text"
                                                className=""
                                                style={{ height: "30px", width: "200px", border: '2px solid black', paddingLeft: '7px' }}
                                                aria-label="Search"
                                                aria-describedby="basic-addon2"
                                                onChange={handleSearch}
                                                value={searchQuery}
                                            />
                                        </div>
                                        <div className="mr-3">
                                            <CSVLink className="downloadbtn btn btn-primary "
                                                filename={`${reportType}.csv`}
                                                data={csv_data}>Export</CSVLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <Modal
                                        style={{ textAlign: "center" }}
                                        size="xl"
                                        show={show}
                                        onHide={handleClose}
                                    >
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <DateRangePicker
                                                onChange={(item) => setFilter([item.selection])}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                ranges={filter}
                                                direction="horizontal"
                                            />
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button
                                                className="btn btn-success"
                                                type="button"
                                                onClick={() => {
                                                    handleClose()
                                                }}
                                            >
                                                Set
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                {
                                    message && (<><br /><br /><span style={{ color: 'red', marginLeft: '10%' }}>{message}</span></>)
                                }
                                <div className="p-2">
                                    <div className='pt-2' />
                                    {selectLoader ?  <RootLoader /> : null}
                                    {filteredData.length > 0 && !loader ?
                                        <div className="table-responsive">
                                            <table
                                                search="true"
                                                className='table table-bordered mb-1 rwd-table' id="user-reports-table"
                                                data-pagination="true"
                                            >
                                                <thead style={{ backgroundColor: '#F4FAF7' }}>
                                                    <tr>
                                                        {
                                                            columns.map((column, index) => {
                                                                return (
                                                                    <th key={index}>{column}</th>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredData.map((record) => {
                                                            return (
                                                                <tr key={record.taskID}>
                                                                    {
                                                                        columns.map((column, index) => {
                                                                            return (
                                                                                <td key={index}><p style={{ fontWeight: 'bold' }}>{record[column] ? record[column] : '-'}</p></td>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        : loader ?
                                            <RootLoader /> :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeReports