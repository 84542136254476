import { isLoaded, isLoading } from "../actions";
import { addCommentUserStory } from "./addCommentUserStory";
import Alert from "../../Alert";
import API from "../../Network/API";
import moment from "moment";
import { addTasksComment } from "../../../UserModule/Backlogs/Services/addTasksComment";
import { logEntry } from "../../logEntry";

//For Delegate MainTask
export async function delegateMainTask(activities,targetDate, state, dispatch, getUser, ideaId, moduleId, handleClose, MODULE, userInfo, task_type) {
  dispatch(isLoading());
  var date = moment();
  var currentDate = date.format('YYYY-MM-D');
  const currentPSTDate = currentDate.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });
  if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "") {
    try {
      const start_time=Date.now();
      var response = await API.post("manage_userstories.php", {
        crop: getUser.corp,
        action: task_type === 'sub_task' ? "reassign_sub_task" : "reassign",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        acceptanceCriteria: state.acceptanceCriteria.value,
        module_id: state.moduleId.value,
        idea_id: state.ideaId.value,
        added_to: userInfo.value,
        added_by: getUser.empId,
        mainTaskId: state.taskId.value,
        storyPoints: state.storyPoints.value,
        priorityLevel: state.priorityLevel.value,
        "projectId": getUser.projectId,
        targetDate: state.targetDate.value || currentPSTDate,
        device_id: userInfo.device_id,
        player_id: userInfo.player_id,

      }, {}, false);
      const end_time=Date.now();
      if (response.status === 'True') {
        if (task_type === 'sub_task') {
          addTasksComment(dispatch, getUser, state.taskId.value, "5", `Task delegate to ${userInfo.label}`);
        } else {
          addCommentUserStory(dispatch, getUser, state.taskId.value, `Task delegate to ${userInfo.label}`, "5")
          activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
            sub_activity:"Delegate",
            response_time : (end_time-start_time), task_meta_data:{story_id:state.taskId.value}
    
          })
        }
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
  } else {
    Alert("warning", "please give all the details")
  }
}