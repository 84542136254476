/*
FileName:UserModule/Team/addEmployee.js
purpose:adding squad team
Developers:Naveen Kumar Gade - NKG, Satya Sidda - SS
Created Date:
Updated Date:27/2/2021
 */
import React, { useReducer, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { getCheckEmployee } from './Services/getCheckEmployee';
import { useSelector } from 'react-redux';
import { empReducer, initialState } from './empReducer';
import * as actions from './actions';
import RootLoader from '../../Common/Loader/RootLoader';
import Select from 'react-select';
import { useWebProperties } from '../../Common/webProperties';
import { getAccountProjects } from './Services/getAccountProjects';
import { getEmployees, getProjectBillRate } from './Services/getEmployees';



const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Employee
export default function AddEmployee(props) {

    const classNames = useStyles();
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(empReducer, initialState);
    const [isEditable,setIsEditable] = useState(true)
    const [isBill,setBill]=useState(false);
    const { CONTRIBUTOR, PRODUCT_OWNER, SCRUM_MASTER, LIMITED_ACCESS_CONTRIBUTOR, APP_NAME } = useWebProperties();
    useEffect(() => {
        getAccountProjects(dispatch, getUser.user);
        getEmployees(dispatch, getUser.user);
        // getSquadList(dispatch, getUser.user);
        // eslint-disable-next-line
    }, [])
    var employee = [];

    state.employees.map((employees) => {
        return (
            employee.push({ 'value': employees.id, 'label': employees.email, 'userName': employees.userName, 'mobileNumber': employees.mobileNumber , 'bill_rate':employees.bill_rate,
                'pay_rate':employees.pay_rate,'currency':employees.pay_currency})
        );
    })
  
    var projects = [];
    state.projects.map((project) => {
        return (
            projects.push({ 'value': project.id, 'label': project.value })
        );
    })
    // useEffect(()=>{
    //     getProjectBillRate()
    // },[])
//handle project
const handleProjectChange = async (selectedOption) => {
    const projectId = selectedOption.value;    
    // Dispatch your action
    dispatch(actions.projectSelected(projectId));
    getProjectBillRate(dispatch,projectId)

    if(state.bill_rate.value.bill_rate === '0' || state.bill_rate.value.bill_rate === null){
        setBill(true)
    }
    else{
        dispatch(actions.bill_rate(''))
        setBill(false)
    }

    // try {
    //     // Make the API call
    //     const response = await axios.get(`/api/your-endpoint/${projectId}`);
        
    //     // Do something with the response, e.g., update state or dispatch more actions
    //     console.log('API response:', response.data);
    //     dispatch(actions.updateProjectData(response.data));
    // } catch (error) {
    //     console.error('Error fetching project data:', error);
    // }
};


const currencyOptions = [
    { value: 'INR', label: 'Indian Rupee (INR)' },
    { value: 'USD', label: 'US Dollar (USD)' },
    { value: 'EUR', label: 'Euro (EUR)' },
    { value: 'GBP', label: 'British Pound (GBP)' },
    { value: 'JPY', label: 'Japanese Yen (JPY)' },
    { value: 'AUD', label: 'Australian Dollar (AUD)' },
    // Add more currencies as needed
  ];

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 col-sm-12 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">New Squad Member</h5>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body " style={{ height: '400px', overflow: 'scroll' }}>
                                    <div>

                                        <form >

                                            <div class="form-group required row p-4" style={{ marginTop: '-30px' }}>
                                                {/* <label for="user" className="col-form-label pt-2" style={{ width: '150px' }}>Select Squad Member</label> */}
                                                <Select
                                                    className="form-control"
                                                    placeholder="Search ...."
                                                    value={employee.value}
                                                    isClearable={true}
                                                    onChange={(selectedOption,triggeredAction) => {
                                                        if(triggeredAction.action==='clear'){
                                                            dispatch(actions.fullName(''))
                                                            dispatch(actions.email(''))
                                                            dispatch(actions.mobile(''))
                                                            // dispatch(actions.bill_rate(''))
                                                            dispatch(actions.pay_rate(''))
                                                            dispatch(actions.currency(''))


                                                            dispatch(actions.roleSelected(''))
                                                            setIsEditable(true)
                                                        }
                                                        else{
                                                        dispatch(actions.fullName(selectedOption.userName))
                                                        dispatch(actions.email(selectedOption.label))
                                                        dispatch(actions.mobile(selectedOption.mobileNumber))
                                                        dispatch(actions.bill_rate(selectedOption.bill_rate))
                                                        dispatch(actions.pay_rate(selectedOption.pay_rate))
                                                        dispatch(actions.currency(selectedOption.currency))

                                                        dispatch(actions.roleSelected(selectedOption.value))
                                                        setIsEditable(false)
                                                        }
                                                    }}
                                                    options={[
                                                        {value:null,label:'None'},
                                                        ...employee.filter(option => option.value.trim() !== "" && option.label.trim() !== "")
                                                    ]}
                                                />
                                                <Select
                                                    className="form-control"
                                                    placeholder="Select Project"
                                                    value={projects.value}
                                                     onChange={handleProjectChange}

                                                    // onChange={(selectedOption) => {
                                                    //     dispatch(actions.projectSelected(selectedOption.value))
                                                    // }}
                                                    options={projects}
                                                />

                                                {/* <label for="recipient-ID" class="col-form-label pt-4 control-label'" style={{ width: "80px" }}>Emp ID<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" required="required" class="form-control col-10 ml-2 " id="recipient-empid" name="Emp ID" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.empId.value}
                                                    onChange={(event) => dispatch(actions.empId(event.target.value))} /> */}
                                                    {/* <div class="form-group"> */}
                                                <label for="recipient-name" class="col-form-label pt-4" style={{ width: "100px" }}>Full name<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-fullName" name="Name" style={{ backgroundColor: isEditable ? 'transparent' : '#e9ecef', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.fullName.value}
                                                    disabled = {!isEditable}
                                                    onChange={(event) => dispatch(actions.fullName(event.target.value))} />
                                                     <span class=" col-10 ml-2 " style={{ color: "red", fontSize: '12px', paddingLeft: "15%" }}>{state.fullName.errorStatus ? state.fullName.errormessage : ""}</span>
                                                     </div>
                                                     <div class="form-group required row p-4" style={{marginTop : "-70px"}}>
                                                <label for="recipient-email" class="col-form-label pt-4" style={{ width: "100px" }}>Email<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-email" name="Email" style={{ backgroundColor: isEditable ? 'transparent' : '#e9ecef', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.email.value}
                                                    disabled = {!isEditable}
                                                    onChange={(event) => dispatch(actions.email(event.target.value))} />
                                                     <span class=" col-10 ml-2 " style={{ color: "red", fontSize: '12px', paddingLeft: "15%" }}>{state.email.errorStatus ? state.email.errormessage : ""}</span>
                                                </div>
                                                <div class="form-group required row p-4" style={{marginTop : "-70px"}}>
                                                <label for="recipient-uname" class="col-form-label pt-4" style={{ width: "100px" }}>Phone number</label>
                                                <input type="number" class="form-control col-10 ml-2 " id="recipient-mobile" name="mobile" style={{ backgroundColor: isEditable ? 'transparent' : '#e9ecef', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.mobile.value}
                                                    disabled = {!isEditable}
                                                    onChange={(event) => dispatch(actions.mobile(event.target.value))} />
                                                     {/* <span class=" col-10 ml-2 " style={{ color: "red", fontSize: '12px', paddingLeft: "15%" }}>{state.mobile.errorStatus ? state.mobile.errormessage : ""}</span> */}
                                                     </div>
                                                        
                                                        <div class="form-group required row p-4" style={{marginTop : "-70px"}}>
                                                <label for="recipient-name" class="col-form-label pt-4" style={{ width: "100px" }}>Username<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-name" name="Username" style={{ backgroundColor: isEditable ? 'transparent' : '#e9ecef', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.email.value}
                                                    readonly />

                                            </div>
                                            {/* costing Module */}
                                            <div className="form-group row " style={{ marginTop: '-30px' }}>
      {/* Container for inputs and select, using Bootstrap's grid and flex utilities */}
      <div className="d-flex flex-wrap align-items-center p-4" style={{width:'100%'}}>
        {/* Input Group for Bill Rate */}
        <div className="form-group required d-flex align-items-center mb-3 mr-3">
          <label
            htmlFor="bill-rate"
            className="col-form-label mr-2"
            style={{ width: "auto" }}
          >
            Bill Rate<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="bill-rate"
            name="billRate"
            style={{
              backgroundColor: isEditable ? 'transparent' : '#e9ecef',
              borderBottom: '1px solid black',
              borderTop: '0px',
              borderLeft: '0px',
              borderRight: '0px'
            }}
            onChange={(event) => dispatch(actions.bill_rate(event.target.value))}
             value={state.bill_rate.value   }
            // value={state.bill_rate.value.bill_rate}
            //  state.bill_rate.value.bill_rate ===0
            // readOnly
           // disabled = {!isEditable}
           disabled={isBill}
          />
        </div>

        {/* Input Group for Pay Rate */}
        <div className="form-group required d-flex align-items-center mb-3 mr-3">
          <label
            htmlFor="pay-rate"
            className="col-form-label mr-2"
            style={{ width: "auto" }}
          >
            Pay Rate<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="pay-rate"
            name="payRate"
            style={{
              backgroundColor: isEditable ? 'transparent' : '#e9ecef',
              borderBottom: '1px solid black',
              borderTop: '0px',
              borderLeft: '0px',
              borderRight: '0px'
            }}
           value={state.pay_rate.value}
           onChange={(event) => dispatch(actions.pay_rate(event.target.value))}
           disabled = {!isEditable}

          />
        </div>

        {/* Select Group for Project Selection */}
        <div className="form-group mb-3" style={{width:'150px',backgroundColor: isEditable ? 'transparent' : '#e9ecef',
}}>
        <label
            htmlFor="pay-rate"
            className="col-form-label mr-5"
            style={{ width: "auto" }}
          >
            Currency Type -<span style={{ color: "red" }}>*</span>
          </label>
          <Select
            className="form-control"
          //  value={state.currency.value}
            value={currencyOptions.find(option => option.value === state.currency.value)} 

            //  value={currencyOptions.value}
             disabled = {!isEditable}

            onChange={(selectedOption) => {
              dispatch(actions.currency(selectedOption.value));
            }}
            options={currencyOptions}
          />
        </div>
      </div>
    </div>
 
                                               {/* costing Module */}


                                            <div class="form-group row pl-3" style={{ marginTop: '-30px' }}>
                                                <label for="recipient-name" class="col-form-label pt-2">Select Role :</label>
                                                <div class="form-check form-check-inline pl-2">
                                                    <input class="form-check-input" type="radio" name="userType" id={CONTRIBUTOR} value='Contributor'
                                                        onChange={(event) => dispatch(actions.userType(event.target.value))} checked={state.userType.value === 'Contributor' || state.userType.value === 'Limited Access Contributor' ? true : false} />
                                                    <label>{CONTRIBUTOR}</label>
                                                </div>
                                                {/* checking count of scrum master *[SS]/}
                                               {/* {(state.scrumMasterCount.value) <= '0'? */}
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="userType" id={PRODUCT_OWNER} value='Product Owner'
                                                        onChange={(event) => dispatch(actions.userType(event.target.value))} />
                                                    <label>{PRODUCT_OWNER}</label>
                                                </div>
                                                {/* :null} */}
                                                {/* checking count of product owner master *[SS]/}
                                                {/* {(state.productOwnerCount.value) <= '0'? */}

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="userType" id={SCRUM_MASTER} value='Scrum Master'
                                                        onChange={(event) => dispatch(actions.userType(event.target.value))} />
                                                    <label>{SCRUM_MASTER}</label>
                                                </div>
                                                {state.userType.value === 'Contributor' || state.userType.value === 'Limited Access Contributor' ? <div class="form-group row pl-3" style={{ marginTop: '-30px' }}>
                                                <label for="recipient-name" class="col-form-label pt-2">{CONTRIBUTOR} Access :</label>
                                                <div class="form-check form-check-inline pl-2">
                                                    <input class="form-check-input" type="radio" name="accessType" id={CONTRIBUTOR} value='Contributor'
                                                        onChange={(event) => dispatch(actions.userType(event.target.value))} checked={state.userType.value === 'Contributor' ? true : false} />
                                                    <label>Full Access</label>
                                                </div>
                                               
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="accessType" id={LIMITED_ACCESS_CONTRIBUTOR} value='Limited Access Contributor'
                                                        onChange={(event) => dispatch(actions.userType(event.target.value))} />
                                                    <label>Limited Access</label>
                                                </div>
                                               
                                             

                                            </div>:null}

                                            </div>
                                       

                                        </form>
                                    </div>
                                </div>
                                {state.isLoading ? < RootLoader /> :
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={() => getCheckEmployee(state, dispatch, getUser.user, props.handleClose, APP_NAME)} >Add</button>
                                    </div>}

                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}