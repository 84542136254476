import Alert from "../../../Common/Alert";
import { logEntry } from "../../../Common/logEntry";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";
import { isLoaded, isLoading } from "../actions";
import { addTasksComment } from "./addTasksComment";

export async function getActiveUserStory(dispatch, getUser, story_id, moduleId, handleClose, handleOpen, message,activities) {
  dispatch(isLoading())
  try {
    const start_time=Date.now();
    var response = await API.post(
      "manage_userstories.php",
      {
        crop: getUser.corp,
        story_id: story_id,
        empId: getUser.empId,
        action: "activate_user_story",
      },
      {},
      false
    );
    const end_time=Date.now();
    if (response.status === "true") {
      addCommentUserStory(dispatch, getUser, story_id, message, "1");
      activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
        sub_activity:"In Progress",
        response_time : (end_time-start_time), task_meta_data:{story_id:story_id}

      })

      dispatch(isLoaded())
      handleClose();

    } else if (response.status === "true1") {
      // handleClose();
      const data = { inprogressStoryId: response.data, todoStoryId: story_id, currentSprint: moduleId, assignedTo: response.assignedTo, projectName: response.project_name, story_title: response.story_title, inprogress_story_title: response.inprogress_story_title, us_id: response.us_id, todo_us_id: response.todo_us_id, task_type: 'main_task', inprogress_task_type: response.inprogress_task_type, toDoProjectName: response.project_name }
      handleOpen("changeUserstoryStatus", '0', data); //For change the UserStory Status
      dispatch(isLoaded())
    } else {
      Alert("warning", response.message);

    }
  } catch (error) {
        //error log
        activities &&  logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
          sub_activity:"In Progress",
          error_message:error.message
        })
    dispatch(isLoaded())
    // console.log(error);
  }
}

export async function getActiveSubUserStory(activities,dispatch, getUser, story_id, moduleId, handleClose, handleOpen, message) {
  dispatch(isLoading())
  try {
    const start_time=Date.now();
    var response = await API.post(
      "manage_userstories.php",
      {
        crop: getUser.corp,
        story_id: story_id,
        empId: getUser.empId,
        action: "activate_sub_user_story",
      },
      {},
      false
    );
    const end_time=Date.now(); 
    if (response.status === "true") {
      addTasksComment(dispatch, getUser, story_id, "1", message);
      //activity log for subtask 
      activities && logEntry({user_id:getUser.userName,logging_level:3,
        activity_id:activities["Task Management"],sub_activity:"In Progress",
        response_time : (end_time-start_time), task_meta_data:{story_id:story_id}
      })
      dispatch(isLoaded())
      handleClose();

    } else if (response.status === "true1") {
      // handleClose();
      const data = { inprogressStoryId: response.data, todoStoryId: story_id, currentSprint: moduleId, assignedTo: response.assignedTo, projectName: response.project_name, story_title: response.story_title, inprogress_story_title: response.inprogress_story_title, us_id: response.us_id, todo_us_id: response.todo_us_id, task_type: 'sub_task', inprogress_task_type: response.inprogress_task_type, toDoProjectName: response.project_name }
      handleOpen("changeUserstoryStatus", '0', data); //For change the UserStory Status
      dispatch(isLoaded())
    } else {
      Alert("warning", response.message);

    }
  } catch (error) {
    dispatch(isLoaded())
     //error log -subtask
     activities &&  logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
      sub_activity:"In Progress",
      error_message:error.message
    })
    // console.log(error);
  }
}