import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { Link, Redirect } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import Alert from '../../Common/Alert';
import API from '../../Common/Network/API';
import { isLoaded, isLoading } from '../../Common/Actions/loading';
// eslint-disable-next-line
import SideBar from '../../UserModule/SquadChat/sideNav';
import Header from '../../UserModule/Utility/TopNav/topnav';
import { useWebProperties } from '../../Common/webProperties';
import MainTaskChatBox from '../../Common/ChatMainTask';
import { MESSAGE_REFRESH_TIMER, capitalizeFirstLetter } from '../../Common/commonUsage';
import { isMobile } from 'react-device-detect';
import { logEntry } from '../../Common/logEntry';
// Register the plugins

export default function ReleaseBugUser() {

    const dispatch = useDispatch();
    const loaderStatus = useSelector(state => state.loading.status)
    const getUser = useSelector(state => state.auth)
    const [dataReport, setData] = useState({})
    const { APP_NAME, MESSAGES, CHAT } = useWebProperties();
    //@ts-ignore
    const [buttonClicked, setButtonClicked] = useState(false)
    const [open, setOpen] = useState({ status: false })
    const [cardInfo, setCardInfo] = useState()
    const [errorMessage, setErrorMessage] = useState('')
    const [pendingTasks, setPendingTasks] = useState([])
    const [searchWord, setSearchWord] = useState('')
    const [allMessages, setAllMessages] = useState([])
    const activities = useSelector(state => state.landingReducer.userActivities)

    useEffect(() => {
        getSupportTicketIn()
        getAllUserStoryMessages();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const fetchUserStoryMessages = () => {
            getAllUserStoryMessages()
        };

        // Function to fetch messages and schedule the next fetch after 10 seconds
        const fetchDataAndScheduleNext = () => {
            fetchUserStoryMessages();
            setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER); // Schedule next fetch after 10 seconds
        };

        // Initial fetch when component mounts
        fetchUserStoryMessages();

        // Schedule the next fetch after 10 seconds
        const timerId = setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER);

        // Clean up the timer on component unmount
        return () => clearTimeout(timerId);
        // eslint-disable-next-line
    }, []);

    async function getAllUserStoryMessages() {
        try {
            var response = await API.post(
                "user_story_chat.php",
                {
                    corp_code: getUser.user.corp,
                    action: "getAllTaskMessages",
                },
                {},
                false
            );
            if (response.status === "True") {
                setAllMessages(response.data);
            } else {
                setAllMessages([]);
            }
        } catch (error) {
            Alert("error", error.message);
        }
    }

    const getSupportTicketIn = async () => {
        dispatch(isLoading());
        try {
            var response = await API.post("backlogspage.php", {
                "crop": getUser.user.corp,
                "userType": getUser.user.role,
                "empId": getUser.user.empId,
                "action": "support_ticket_in",
            }, {}, false);
            if (response.status === 'true') {
                dispatch(isLoaded());
                setPendingTasks(response.data)
            }
            else {
                setPendingTasks([])
                dispatch(isLoaded());

            }
        } catch (error) {
            Alert('error', error.message);
            dispatch(isLoaded());
        }
    }

    const handleChange = (e) => {
        setData({
            ...dataReport,
            [e.target.name]: e.target.value
        })
    }

    const sendChat = async (story_id, message, email) => {
        //@ts-ignore
        await API.post("user_story_chat.php", {
            action: "send",
            corp_code: getUser.user.corp,
            groupId: story_id,
            message: message,
            messagedBy: getUser.user.empId,
            msgId: " ",
            groupName: '',
            groupEmail: [email],
            reply_id: "",
        }, {}, false);
        let info = { us_id: story_id, action: 'maintask', id: story_id, project_id: "b9594061fe6c271f0ce5cbcdc7ffb8e5", project_name: 'AgileNexApp' }
        setCardInfo(info)
        setOpen({ open: true });
        setButtonClicked(false)
    }
    const addMainTaskFromSupportToKanban = async () => {
        setErrorMessage('')
        setButtonClicked(true)
        dispatch(isLoading());
        try {
            const { bug_one_sen } = dataReport

            if (!bug_one_sen) {
                // Alert("error", "Please provide at least one input.");
                setButtonClicked(false)
                dispatch(isLoaded());
                setErrorMessage('Please help with above details. This will help us to address your issue promptly and effectively. Thank you.');
                return; // Do not proceed with the submission
            }

            let sanitizedValue;

            if (bug_one_sen.charAt(0).match(/[^a-zA-Z0-9]/)) {
                if (bug_one_sen.length > 1 && bug_one_sen.charAt(1).match(/[a-zA-Z0-9]/)) {
                    sanitizedValue = bug_one_sen;
                } else {
                    sanitizedValue = "I Need Help";
                }
            } else {
                sanitizedValue = bug_one_sen;
            }

            const start_time = Date.now()
            var response = await API.post("manage_userstories.php", {
                // crop: getUser.corp,
                action: "addMainTaskFromSupportToKanban",
                title: sanitizedValue,
                description: sanitizedValue,
                projectId: 'b9594061fe6c271f0ce5cbcdc7ffb8e5',
                assignBy: getUser.user.empId,
                email: getUser.user.userName,
                appName: APP_NAME,
                story_type: 'support_ticket_in'
            }, {}, false);
            const end_time = Date.now()
            if (response.status === 'True') {
                sendChat(response.story_id, sanitizedValue, response.manager_email);
                dispatch(isLoaded());
                // Alert("success", 'Thankyou for contacting us, we will reach out to you once the issue is fixed.');
                // setButtonClicked(false)
                activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities['Inside Support Ticket'],task_meta_data:{task_id:response.story_id},response_time:(end_time-start_time)})
            }
            else if (response.status === 'false') {
                dispatch(isLoaded());
                setButtonClicked(false)
                activities && logEntry({user_id:getUser.user.userName,logging_level:1,activity_id:activities['Inside Support Ticket'],error_message:"Inside support ticket generation failed"})
            }
        } catch (error) {
            Alert('error', error.message);
            dispatch(isLoaded());
            setButtonClicked(false)
            activities && logEntry({user_id:getUser.user.userName,logging_level:1,activity_id:activities['Inside Support Ticket'],error_message:error.message})
        }

    }

    const handleClose = () => {
        setOpen({ status: false })
        setData({ bug_one_sen: '' })
        getSupportTicketIn()
    };

    const handleModalClose = () => {
        setOpen({ status: false });
        setData({ bug_one_sen: '' })
        getSupportTicketIn()
    }

    const getMessagesCount = (data, msg, task, empId) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task.id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return (
            <i>
                {msgCount.length > 0 ?
                    <div style={{ display: 'flex', marginRight: '-8px' }}>
                        <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
                            onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
                        <span style={{ color: 'red', fontWeight: "bold", marginTop: '-4px' }}>{msgCount.length > 9 ? "+9" : msgCount.length}</span>
                    </div>
                    :
                    <div style={{ display: 'flex' }}>
                        <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
                            onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
                    </div>}
            </i>
        )
    };

    const handleChatOpen = (action, index, sno) => {
        var info;
        setOpen({ status: true, action: action });
        if (action === "maintask") {
            info = { us_id: index.id, action: 'maintask', id: index.id, project_id: "b9594061fe6c271f0ce5cbcdc7ffb8e5", project_name: 'AgileNexApp', sno: sno }
        }
        setCardInfo(info);
        setOpen({ open: true });
    }

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card" >
                            <div className="card">
                                <div className="card-body">
                                    <div className="mt-1">
                                        <div className="accordion" id="accordionExample">
                                            <div className="card">
                                                <div id="collapseZero" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                    <div className="card-body" style={{ paddingTop: "0px" }}>
                                                        <div className="releasenote-cardbody">
                                                            <div className="card-title text-capitalize mt-3 mb-3"><h6 style={{ whiteSpace: 'pre-wrap' }}>Could You Please Describe The Issue In One Sentence?</h6></div>
                                                            <div className="col-12 row">
                                                                <div className="col-10 form-group" style={{ height: 'auto' }}>
                                                                    <input type="text" onChange={e => handleChange(e)} className="form-control" id="title" name="bug_one_sen"
                                                                        value={dataReport?.bug_one_sen} placeholder="Type your issue" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-2px' }} />
                                                                </div>
                                                                <div className='col-2'>
                                                                    <button type="submit" disabled={buttonClicked} style={{ background: 'transparent', border: 'none', marginLeft: isMobile ? '-25px' : {} }}>
                                                                        <img src="images/common/chat.svg" title={MESSAGES} alt="logo" style={{ width: '50px', cursor: 'pointer' }}
                                                                            onClick={() => addMainTaskFromSupportToKanban()} />
                                                                        <h6 style={isMobile ? { width: '100px' } : {}}>Let’s Chat </h6>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {loaderStatus && <div style={{ color: 'green', fontSize: '15px' }}>We are connecting to our support team, please wait....</div>}
                                                            {errorMessage && <div style={{ color: 'red', fontSize: '15px' }}>{errorMessage}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card" style={{ marginBottom: '50px' }}>
                                <div className="card-body" style={{ overflowX: "auto" }}>
                                    <div className="justify-content-between row">
                                        <h4 className="card-title" style={{ paddingLeft: 10, paddingTop: 10 }}>Previous Tickets</h4>
                                        <div className="d-flex justify-content-end mb-3 mt-2">
                                            <div class="input-group-prepend">
                                                <span class="text" style={{ color: 'black', marginTop: '3px', fontSize: '13px', paddingRight: 10 }}>Search: </span>
                                            </div>
                                            <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '35px' }}
                                                onChange={(event) => setSearchWord(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* eslint-disable-next-line */}
                                    <table>
                                        <tbody>
                                            {/* eslint-disable-next-line */}
                                            {pendingTasks.length > 0 ? pendingTasks.filter((val) => {
                                                if (searchWord === "") {
                                                    return val
                                                } else if (val.tasktitle.toLowerCase().includes(searchWord.toLowerCase()) || val.id.toLowerCase().includes(searchWord.toLowerCase()) || val.completeStatus.toLowerCase().includes(searchWord.toLowerCase())) {
                                                    return val
                                                }
                                            }).map((todo, index) => {
                                                const { id, tasktitle, completeStatus } = todo;
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ border: '1px solid #e1e1e1' }}>
                                                            <div className="col-12 td1">
                                                                <div className="col-12" >
                                                                    <div class="d-flex col-12">
                                                                        <div class="p-2" style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
                                                                            <b style={{ cursor: 'pointer' }} > <p style={{ color: 'black', textTransform: 'capitalize' }}>AG{'-'}{id}{'-'}{tasktitle}</p> </b>
                                                                        </div>
                                                                        <div style={{ width: isMobile ? 'auto' : '20%', backgroundColor: completeStatus === 'pending' ? '#e8e35f' : '#6fe2ac', cursor: 'pointer', color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom"  >{capitalizeFirstLetter(completeStatus)}</div>
                                                                        <div style={{ flex: '1', width: '10%', padding: '5px', marginLeft: '5px', marginTop: '2px', textAlign: "end" }}>
                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                                                                {
                                                                                    getMessagesCount(index, allMessages, todo, getUser.user.empId)
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        open.open &&
                        <MainTaskChatBox open={open.open} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} />
                    }
                </div>
            </div>
        </div>



    )
}
