import React, { useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import { empReducer, initialState } from './empReducer';
import * as actions from './actions';
import { useWebProperties } from '../../Common/webProperties';
import { updateEmployee } from './Services/updateEmployee';
import Select from 'react-select';


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function EditEmployee(props) {
    const classNames = useStyles();
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(empReducer, initialState);
    const { CONTRIBUTOR, SCRUM_MASTER, PRODUCT_OWNER, LIMITED_ACCESS_CONTRIBUTOR, APP_NAME, SUPPORT_ROLE, SUPPORT_MANAGER, L1, L2 } = useWebProperties();
    useEffect(() => {
        dispatch(actions.setModifyEmployee(props.data.id, props.data.employeeId, props.data.name, props.data.email, props.data.mobile, props.data.userName, props.data.team, props.data.designation, props.data.reportingManager, props.data.functionalManager, props.data.userType, props.data.userStatus, props.data.display_name, props.data.support_role,props.data.bill_rate,props.data.pay_rate,props.data.pay_currency
        ))
        // eslint-disable-next-line
    }, [])
    const currencyOptions = [
        { value: 'INR', label: 'Indian Rupee (INR)' },
        { value: 'USD', label: 'US Dollar (USD)' },
        { value: 'EUR', label: 'Euro (EUR)' },
        { value: 'GBP', label: 'British Pound (GBP)' },
        { value: 'JPY', label: 'Japanese Yen (JPY)' },
        { value: 'AUD', label: 'Australian Dollar (AUD)' },
        // Add more currencies as needed
      ];


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 col-sm-12 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">Modify Employee Details</h5>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body" style={{ height: '400px', overflow: 'scroll' }}>
                                    <div>

                                        <form >

                                            <div class="form-group row p-2" style={{ marginTop: '-30px' }}>

                                                <label for="recipient-ID" class="col-form-label pt-4" style={{ width: "100px" }}>Emp ID<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-empid" name="Emp ID" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.empId.value}
                                                    onChange={(event) => dispatch(actions.employeeId(event.target.value))} />
                                                <label for="recipient-name" class="col-form-label pt-4" style={{ width: "100px" }}>Full name<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-fullName" name="Name" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.fullName.value}
                                                    onChange={(event) => dispatch(actions.fullName(event.target.value))} />
                                                <label for="recipient-name" class="col-form-label pt-4" style={{ width: "100px" }}>Display name</label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-displayName" name="Display Name" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.display_name.value}
                                                    onChange={(event) => dispatch(actions.display_name(event.target.value))} />

                                                <label for="recipient-email" class="col-form-label pt-4" style={{ width: "100px" }}>Email<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-email" name="Email" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.email.value}
                                                />
                                                <label for="recipient-uname" class="col-form-label pt-4" style={{ width: "100px" }}>Phone number</label>
                                                <input type="tel" class="form-control col-10 ml-2 " id="recipient-mobile" name="mobile" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.mobile.value} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                                                    onChange={(event) => dispatch(actions.mobile(event.target.value))} />
                                                <label for="recipient-name" class="col-form-label pt-4" style={{ width: "100px" }}>Username<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-name" name="Username" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.email.value} />
                                            </div>
                                              
                                              {/* costing */}
                                              <div className="form-group row " style={{ marginTop: '-30px' }}>
      <div className="d-flex flex-wrap align-items-center p-4" style={{width:'100%'}}>
        {/* Input Group for Bill Rate */}
        <div className="form-group required d-flex align-items-center mb-3 mr-3">
          <label
            htmlFor="bill-rate"
            className="col-form-label mr-2"
            style={{ width: "auto" }}
          >
            Bill Rate<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="bill-rate"
            name="billRate"
            style={{
            //   backgroundColor: isEditable ? 'transparent' : '#e9ecef',
              borderBottom: '1px solid black',
              borderTop: '0px',
              borderLeft: '0px',
              borderRight: '0px'
            }}
            onChange={(event) => dispatch(actions.billRate(event.target.value))}
          //  onChange={(event) => dispatch(actions.bill_rate(event.target.value))}
            value={state.billRate.value}
            // readOnly
          />
        </div>

        {/* Input Group for Pay Rate */}
        <div className="form-group required d-flex align-items-center mb-3 mr-3">
          <label
            htmlFor="pay-rate"
            className="col-form-label mr-2"
            style={{ width: "auto" }}
          >
            Pay Rate<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="pay-rate"
            name="payRate"
            style={{
           //   backgroundColor: isEditable ? 'transparent' : '#e9ecef',
              borderBottom: '1px solid black',
              borderTop: '0px',
              borderLeft: '0px',
              borderRight: '0px'
            }}
          value={state.payRate.value}
         onChange={(event) => dispatch(actions.payRate(event.target.value))}
        
          />
        </div>

        {/* Select Group for Project Selection */}
        <div className="form-group mb-3" style={{width:'150px'}}>
        <label
            htmlFor="pay-rate"
            className="col-form-label mr-5"
            style={{ width: "auto" }}
          >
            Currency Type <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            className="form-control"
          // value={state.currency.value}
        value={currencyOptions.find(option => option.value === state.userCurrency.value)} 

            onChange={(selectedOption) => {
             dispatch(actions.userCurrency(selectedOption.value));
            }}
            options={currencyOptions}
          />
        </div>
      </div>
    </div>

                                              {/*  */}


                                            {!props.data.isStakeHolder && (
                                                <div class="form-group row pl-2" style={{ marginTop: '-10px' }}>
                                                    <label for="recipient-name" class="col-form-label pt-2">Select User Type :</label>
                                                    <div class="form-check form-check-inline pl-2">
                                                        <input class="form-check-input" type="radio" name="userType" id={CONTRIBUTOR} value='Contributor' checked={state.userType.value === 'Contributor' || state.userType.value === 'Limited Access Contributor' ? true : false}
                                                            onChange={(event) => {
                                                                dispatch(actions.userType(event.target.value))
                                                                dispatch(actions.supportRole(null))
                                                            }} />
                                                        <label>{CONTRIBUTOR}</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="userType" id={SCRUM_MASTER} value='Scrum Master' checked={state.userType.value === 'Scrum Master' ? true : false}
                                                            onChange={(event) => {
                                                                dispatch(actions.userType(event.target.value))
                                                                dispatch(actions.supportRole(null))
                                                            }} />
                                                        <label>{SCRUM_MASTER}</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="userType" id={PRODUCT_OWNER} value='Product Owner' checked={state.userType.value === 'Product Owner' ? true : false}
                                                            onChange={(event) => {
                                                                dispatch(actions.userType(event.target.value))
                                                                dispatch(actions.supportRole(null))
                                                            }} />
                                                        <label>{PRODUCT_OWNER}</label>
                                                    </div>
                                                </div>
                                            )}

                                            {props.data.isStakeHolder && <label for="recipient-name" class="col-form-label pt-2">User Role: {PRODUCT_OWNER}</label>}

                                            {state.userType.value === 'Contributor' || state.userType.value === 'Limited Access Contributor' ?
                                                <div class="form-group row pl-2" style={{ marginTop: '-30px' }}>
                                                    <label for="recipient-name" class="col-form-label pt-2">Contributor Access :</label>
                                                    <div class="form-check form-check-inline pl-2">
                                                        <input class="form-check-input" type="radio" name="accessType" id={CONTRIBUTOR} value='Contributor'
                                                            onChange={(event) => dispatch(actions.userType(event.target.value))} checked={state.userType.value === 'Contributor' ? true : false} />
                                                        <label>Full Access</label>
                                                    </div>

                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="accessType" id={LIMITED_ACCESS_CONTRIBUTOR} value='Limited Access Contributor'
                                                            onChange={(event) => dispatch(actions.userType(event.target.value))} checked={state.userType.value === 'Limited Access Contributor' ? true : false} />
                                                        <label>Limited Access</label>
                                                    </div>



                                                </div> : null}

                                            {state.userType.value === 'Contributor' || state.userType.value === 'Limited Access Contributor' ?
                                                <div class="form-group row pl-2" style={{ marginTop: '-30px' }}>
                                                    <label for="recipient-name" class="col-form-label pt-2">{SUPPORT_ROLE}:</label>
                                                    <div class="form-check form-check-inline pl-2">
                                                        <input class="form-check-input" type="checkbox" name="supportRole" id={L1} value='L1'
                                                            onChange={(event) => {
                                                                if (event.target.checked) {
                                                                    dispatch(actions.supportRole(event.target.value))
                                                                } else {
                                                                    dispatch(actions.supportRole(null))
                                                                }
                                                            }
                                                            }
                                                            checked={state.supportRole.value === 'L1' ? true : false}
                                                        />
                                                        <label>L1</label>
                                                    </div>

                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="supportRole" id={L2} value='L2'
                                                            onChange={(event) => {
                                                                if (event.target.checked) {
                                                                    dispatch(actions.supportRole(event.target.value))
                                                                } else {
                                                                    dispatch(actions.supportRole(null))
                                                                }
                                                            }} checked={state.supportRole.value === 'L2' ? true : false} />
                                                        <label>L2</label>
                                                    </div>
                                                    <div class="form-check form-check-inline pl-2">
                                                        <input class="form-check-input" type="checkbox" name="supportRole" id={SUPPORT_MANAGER} value='Support Manager'
                                                            onChange={(event) => {
                                                                if (event.target.checked) {
                                                                    dispatch(actions.supportRole(event.target.value))
                                                                } else {
                                                                    dispatch(actions.supportRole(null))
                                                                }
                                                            }} checked={state.supportRole.value === 'Support Manager' ? true : false} />
                                                        <label>Support Manager</label>
                                                    </div>


                                                </div> : null}

                                        </form>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={() => updateEmployee(state, dispatch, getUser.user, props.handleClose, APP_NAME)} >Modify</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}