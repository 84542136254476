import React, { useEffect, useReducer, useRef } from 'react';
import {
    Inject, ScheduleComponent, Day, Week, WorkWeek,
    Month, Agenda, DragAndDrop, Resize
} from '@syncfusion/ej2-react-schedule';
import API from '../Network/API';
import { applyCategoryColor } from './helper';
import { initialState, reducer } from './reducer';
import { getActiveSprints, getEvent } from './Services/getEvent';
import { getEmployees } from './Services/getEmployees';
import { getModules } from './Services/getModules';
import { getToDo } from './Services/getToDo';
import { getSprint } from './Services/getSprint';
import Header from '../TopNav';
import AdminSideBar from '../../UserModule/Utility/SideNav';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { isLoaded, isLoading } from '../Actions/loading';
import Alert from '../Alert';
import { DropDownList, MultiSelect } from '@syncfusion/ej2-dropdowns';
import { createElement, L10n } from '@syncfusion/ej2-base';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import "./calendar.css"
import RootLoader from '../Loader/RootLoader';
import { Client_URL, Meet_URL } from '../config';
import { useWebProperties } from '../webProperties';
import { getGroup } from '../TasksModals/Services/getGroup';
import { tasksReducer, initialState as initialGroupData } from '../TasksModals/tasksReducer';
import { removeDuplicatesFromString, removeSpecialCharacters } from '../commonUsage';
import { getProjects } from '../TasksModals/Services/getProjects';
import { createCalendarTask } from './Services/createCalendarTask';
import { createCalendargroupTask } from './Services/createCalendarGroupTask';
import moment from 'moment';
import { isFetch, isFetched } from './action';
import { addCommentUserStory } from '../../UserModule/Modules/Services/addCommentUserStory';
import { getSubStringId } from '../SubStringConvert';
import jwt from 'jsonwebtoken';
import { updateUserStory } from '../../UserModule/ConsolidatedToDo/Services/updateUserStory';
import { logEntry } from '../logEntry';

// to change names of buttons
L10n.load({
    'en-US': {
        'schedule': {
            'editSeries': '',
            'deleteSeries': '',
            'editFollowingEvent': 'Following Events',
        },
    }
});


function Calender() {

    // eslint-disable-next-line
    const [state, dispatch] = useReducer(reducer, initialState);
    const { APP_NAME, URL,MAINTASK_ADD,MAINTASK ,MODULE, TASKS_WARNING, ROADBLOCK_WARNING} = useWebProperties();
    const getUser = useSelector(state => state.auth)
    const [state1, dispatch1] = useReducer(tasksReducer, initialGroupData);
    const activities = useSelector(state => state.landingReducer.userActivities)
    const scheduleObj = useRef(null);
    useEffect(() => {
        const fetchData = async () => {
            await getEmployees(dispatch, getUser.user);
            await getEvent(getUser.user, dispatch, APP_NAME);
            await getModules(dispatch, getUser.user);
            await getSprint(dispatch, getUser.user);
            await getGroup(dispatch1, getUser.user);
            await getProjects(dispatch1,getUser.user);
            await getActiveSprints(dispatch, getUser.user)
        };
    
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateTaskMeetingUrl = async (employe) => {
        const secretKey = "RALLYWETASKER";

        // Sample payload data
        const payload = {
            "context": {
                "user": {
                    "avatar": "",
                    "name": "",
                    "email": "",
                },
            },
            "aud": "jitsi",
            "iss": "23456789",
            "sub": Meet_URL,
            "room": removeSpecialCharacters(`${getSubStringId(employe[0].name, 2)}-${employe[0].u_id}`),
            // "exp": 1700060038,
        };
        const generatedToken = jwt.sign(payload, secretKey);
        const meeting_url = `https://${Meet_URL}/${removeSpecialCharacters(`${getSubStringId(employe[0].name, 2)}-${employe[0].u_id}`)}?jwt=${generatedToken}#jitsi_meet_external_api_id=98765432&config.startWithAudioMuted=true&config.startWithVideoMuted=true&config.autoCaptionOnRecord=true`
        try {
            // eslint-disable-next-line
            const response = await API.post("manage_userstories.php", {
                action: "updateMeetingUserURL",
                us_id: employe[0].u_id,
                meeting_url: meeting_url
            }, {}, false);
            if (response.status === "True") {
                return response.meeting_url.split('?')[0]
            }else{
                return ""
            }
        } catch (error) {
            console.log(error)
        }
    }
    // for add validation to the fields
    const fields = {
        subject: { name: 'Subject', validation: { required: true } },
        startTime: { name: 'StartTime', validation: { required: true } },
        endTime: { name: 'EndTime', validation: { required: true } },
        description: { name: 'Description', validation: { required: true } },
    };
    var sprints = []
    state.modules.map((module) => {
        return (
            sprints.push({
                'value': module.moduleId, 'label': module.moduleDesc, assignbyId: module.assignbyId,
                average: module.average,
                createdBy: module.createdBy,
                created_on: module.created_on,
                ideaId: module.ideaId,
                ideaTitle: module.ideaTitle,
                moduleDesc: module.moduleDesc,
                moduleId: module.moduleId,
                startDate: module.startDate,
                status: module.status,
                targetDate: module.targetDate
            })
        );
    })
    var stories = []
    // user stories to display on calendar
    const getSection=state.section.filter(item=>item.assigned_by===getUser.user.empId||item.assigned_to===getUser.user.empId)
    getSection.map((story) => {
        return (
            stories.push({
                Subject: story.story_title,
                EndTime: story.target_date,
                StartTime: story.target_date,
                // make it all day event to display it on top of calendar
                IsAllDay: "1",
                IsReadonly: "1",
                Color: '#df5286',
                Members: story.assignedto,
                Location: story.active_status === "0" ? "To Do"
                    : story.active_status === "1" ? "In Progress"
                        : story.active_status === "-1" ? "Blocked" : "Completed"
            })
        );
    })

    var employeeData = state.employees = state.employees.map(employee => {
        return { ...employee, from: 'user' };
    })

    const deleteTask = (taskToDelete, addedEmp) => {
        const tasks = employeeData.filter(task => task.email !== taskToDelete);
        // eslint-disable-next-line
        tasks.unshift(addedEmp)
        return { tasks };
    }
    const employeeDetails = deleteTask(getUser.user.userName, {
        id: getUser.user.empId, name: getUser.user.fullName,
        email: getUser.user.userName, userName: getUser.user.userName, role: getUser.user.role, empStatus: getUser.user.empStatus,from:"user",
    })

    employeeData=employeeDetails.tasks.filter(user=>user.email!==""&&user.email!==null)

    var groupData = state1.groups = state1.groups.map(groups => {
        return {
            ...groups, from: 'group', email: groups.id, name: `${groups.group_name}-[${removeDuplicatesFromString(groups.members_name)}]`
        };
    })


    var combinedEmpArray = [...employeeData,...groupData]

    const getDate=async(data)=>{
        let newDate = new Date(data?.StartTime);
        let day =newDate.getDate(); 
        let month = newDate.getMonth();
        let year = newDate.getFullYear(); 

        return (`${year}-${month + 1}-${day}`);
    }

    const checkBetween=async(date)=>{
        let startDate = new Date(state.activeSprint.startDate);
        let endDate = new Date(state.activeSprint.targetDate);
        let checkDate = new Date(date);

        if (checkDate >= startDate && checkDate <= endDate) {
            return true
        } else {
            return false
        }
    }

    const validateDate=async(data)=>{
        let startDate = new Date();
        let checkDate = new Date(data);

        if (checkDate >= startDate) {
            return true
        } else {
            return false
        }
    }

    const modifyDates=async(data)=>{
        const start = new Date(data.StartTime);
        const end = new Date(data.EndTime);
        const startDate = `${start.getMonth() + 1}/${start.getDate()}/${start.getFullYear().toString().slice(-2)} ${start.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`;
        const endDate = `${end.getMonth() + 1}/${end.getDate()}/${end.getFullYear().toString().slice(-2)} ${end.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`;
        return {startDate,endDate}
    }

    const createEvent=async(data)=>{
        const value=data.Members[0]
        const changeDate=await modifyDates(data)
        let assignee
        let eventType="user"
        if (!isNaN(parseInt(value))){
            assignee=groupData.filter((item)=>item.id===value)
            const selectedMembers=(assignee[0].members_email).split(",").filter(email=>email!=='')
            data.Members=selectedMembers
            eventType="group"
        }
        dispatch(isLoading());
        try {
            const start_time = Date.now()
            // eslint-disable-next-line
            var response = await API.post("calendar.php", {
                urlBase: urlBase,
                addEvent: data,
                corp_code: getUser.user.corp,
                action: "Add",
                emp_id:getUser.user.empId,
                projectId: getUser.user.projectId,
                fullName: getUser.user.fullName,
                userName: getUser.user.userName,
                url: Client_URL,
                appName: APP_NAME,
                start_time:changeDate.startDate,
                end_time:changeDate.endDate,
                event_type:eventType,
            }, {}, false);
            const end_time = Date.now()
            if (response.status === "True") {
                console.log("Event created successfully")
                dispatch(isLoaded());
                if(data.isGenerate){
                    createTask(data,assignee,response.event_id);
                }else{
                    await getEvent(getUser.user, dispatch, APP_NAME)
                    dispatch(isFetched());
                }
                activities && logEntry({
                    user_id: getUser.user.userName, logging_level: 3,
                    activity_id: activities["Calendar"], sub_activity: "Create Event",response_time:(end_time-start_time)
                  })
            }
            else {
                console.log("Something went wrong")
                await getEvent(getUser.user, dispatch, APP_NAME)
                dispatch(isLoaded());
                dispatch(isFetched());
                activities && logEntry({
                    user_id: getUser.user.userName, logging_level: 2,
                    activity_id: activities["Calendar"], sub_activity: "Create Event"
                })
            }
        } catch (error) {
            Alert('error', error.message);
            await getEvent(getUser.user, dispatch, APP_NAME)
            dispatch(isLoaded());
            dispatch(isFetched());
            activities && logEntry({
                user_id: getUser.user.userName, logging_level: 2,
                activity_id: activities["Calendar"], sub_activity: "Create Event",
                error_message: error.message
            })
        }
    }

    const createTask=async(data,assignee,eventId)=>{
        dispatch(isLoading());
        try{
            const date=await getDate(data);
            const inBetween=await checkBetween(date)
            const sprintDesc=state.modules[0].moduleDesc

            let epicId=""
            if(state1.projects.length>0){
                const userEpic=state1.projects.filter((epic)=>epic.idea_title==="Default Module")
                epicId=userEpic[0].id
            }
            let selectedMembers=[]
            if (assignee!==undefined) {
                const details={
                    taskTitle:{value:data.Subject},
                    taskDescription:{value:data.Description},
                    userSelected:{value:assignee[0].id,label:assignee[0].name,from:assignee[0].from,members_email:assignee[0].members_email},
                    acceptanceCriteria:{value:data.Description},
                    storySelected:"",
                    prioritySelected:"",
                    epicSelected:epicId,
                    player_id:"",
                    moduleId:inBetween?state.modules[0]?.moduleId:null,
                    targetDate:moment(data?.StartTime).format('YYYY-MM-DD'),
                    eventId:eventId,
                    from_screen:"calendar",
                    projectId:getUser.user.projectId,
                    corp:getUser.user.corp,
                    location:data.Location,
                }
                await createCalendargroupTask(activities,details, dispatch, getUser.user,MAINTASK_ADD,MAINTASK,MODULE,sprintDesc)
                await getEvent(getUser.user, dispatch, APP_NAME)
                dispatch(isLoaded());
                dispatch(isFetched());
            } else {
                selectedMembers=data.Members
                await selectedMembers.forEach((item)=>{
                    const user=employeeData.filter((userList)=>userList.email===item)
                    const details={
                        taskTitle:{value:data.Subject},
                        taskDescription:{value:data.Description},
                        userSelected:{value:user[0].id,label:user[0].name,from:user[0].from,email:user[0].email},
                        acceptanceCriteria:{value:data.Description},
                        storySelected:"",
                        prioritySelected:"",
                        epicSelected:epicId,
                        player_id:"",
                        moduleId:inBetween?state.modules[0]?.moduleId:null,
                        targetDate:moment(data.StartTime).format('YYYY-MM-DD'),
                        eventId:eventId,
                        projectId:getUser.user.projectId,
                        corp:getUser.user.corp,
                        from_screen:"calendar",
                        location:data.Location,
                    }
                    createCalendarTask(activities,details, dispatch, getUser.user,MAINTASK,MODULE,sprintDesc)
                })
                await getEvent(getUser.user, dispatch, APP_NAME)
                dispatch(isLoaded());
                dispatch(isFetched());
            }
        } catch (error) {
            console.error('Error creating task:', error);
            await getEvent(getUser.user, dispatch, APP_NAME)
            dispatch(isLoaded());
            dispatch(isFetched());
        }    
    }

    const urlBase = `${URL}calendarPathSetUp?key=${getUser.user.corp}&projectId=${getUser.user.projectId}&empId=`;

    const modifyEvent=async(updateObj,prevObj)=>{
        dispatch(isFetch());
        const date=await getDate(updateObj)
        const inBetween=await checkBetween(date)
        const changeDate=await modifyDates(updateObj)
        try {
            const start_time = Date.now()
            // eslint-disable-next-line
            var response = await API.post("calendar.php", {
                urlBase: urlBase,
                action: "InsertUpdate",
                corp_code: getUser.user.corp,
                current: updateObj,
                projectId: getUser.user.projectId,
                fullName: getUser.user.fullName,
                userName: getUser.user.userName,
                url: Client_URL,
                appName: APP_NAME,
                target_date:moment(updateObj?.StartTime).format('YYYY-MM-DD'),
                start_time:changeDate.startDate,
                end_time:changeDate.endDate,
                module_id:inBetween?state.modules[0]?.moduleId:null,
            }, {}, false);
            const end_time = Date.now()
            await getEvent(getUser.user, dispatch, APP_NAME);
            dispatch(isFetched())
            activities && logEntry({
                user_id: getUser.user.userName, logging_level: 3,
                activity_id: activities["Calendar"], sub_activity: "Modify Event",response_time:(end_time-start_time)
              })
        } catch (error) {
            await getEvent(getUser.user, dispatch, APP_NAME);
            Alert('error', error.message);
            dispatch(isFetched())
            activities && logEntry({
                user_id: getUser.user.userName, logging_level: 2,
                activity_id: activities["Calendar"], sub_activity: "Modify Event",
                error_message: error.message
            })
        }
    }

    // for handling operations performed by calendar like add, delete, edit, resize, drag and drop 
    const handleData = async (e) => {
        const data = e.result
        const length = e.result.length
        dispatch(isLoading());
        if (scheduleObj.current.currentAction === "Delete" || scheduleObj.current.currentAction === "DeleteSeries") {
            const currentAction=scheduleObj.current.currentAction
            const object=scheduleObj.current.activeEventData.event
            if (object?.emp_id===getUser.user.empId){
                dispatch(isFetch())
                try {
                    const start_time = Date.now()
                    // eslint-disable-next-line
                    var response = await API.post("calendar.php", {
                        urlBase: urlBase,
                        action: currentAction,
                        current: object,
                        corp_code: object.project_name,
                        projectId: object.ProjectName,
                        fullName: getUser.user.fullName,
                        userName: getUser.user.userName,
                        url: Client_URL,
                        appName: APP_NAME,
                    }, {}, false);
                    const end_time = Date.now()
                    if(response.status==="True"&&response.task_details.length>0){
                        await response?.task_details?.forEach(item=>{
                            if(item.active_status==="1"){
                                let data = { task_id: item.task_id, story_id: item.story_id, task_type: item.task_type, project_name: item.project_name, main_task_id: item.main_task_id, story_type: item.story_type, us_id: item.us_id, story_title: item.story_title, blocked_id: item.blocked_id, userStoryId: item.userStoryId,calendarId:item?.calendar_id };
                                let message = `This meeting, ${MAINTASK} is completed`;
                                updateUserStory(activities,getUser.user, data.story_id, "handleClose", message, TASKS_WARNING, ROADBLOCK_WARNING, data.story_type, data.us_id, data.main_task_id, MAINTASK,data.calendarId)
                            }
                        })
                        dispatch(isLoaded())
                    }
                    await getEvent(getUser.user, dispatch, APP_NAME);
                    dispatch(isFetched())
                    activities && logEntry({
                        user_id: getUser.user.userName, logging_level: 3,
                        activity_id: activities["Calendar"], sub_activity: "Delete Event",response_time:(end_time-start_time)
                      })
                } catch (error) {
                    console.log(error)
                    Alert('error', error.message);
                    dispatch(isLoaded())
                    dispatch(isFetched())
                    activities && logEntry({
                        user_id: getUser.user.userName, logging_level: 2,
                        activity_id: activities["Calendar"], sub_activity: "Delete Event",
                        error_message: error.message
                    })
                }
            }else{
                if(object?.emp_id!==undefined){
                    Alert("warning","You are not authorized to delete this event")
                    await getEvent(getUser.user, dispatch, APP_NAME);
                    dispatch(isLoaded())
                    activities && logEntry({
                        user_id: getUser.user.userName, logging_level: 2,
                        activity_id: activities["Calendar"], sub_activity: "Delete Event",
                        error_message: "You are not authorized to delete this event"
                    })
                } 
            }
        } else if (scheduleObj.current.currentAction === "Add" && scheduleObj.current.activeEventData.event === undefined) {
            dispatch(isLoading())
            const validate=await validateDate(e.result[length-1].StartTime)
            if(validate){
                if(e.result[length-1]?.Members.length>0&&e.result[length-1]?.Subject.trim()!==""&&e.result[length-1]?.Description.trim()!==""){
                    if(e.result[length-1].Members.length>1 && !(e.result[length-1].Members.every(item =>isNaN(item)))){
                        Alert("error","Please select only users or only one group")
                        dispatch(isLoaded());
                        await getEvent(getUser.user, dispatch, APP_NAME);
                    }else{
                        dispatch(isFetch());
                        createEvent(e.result[length-1]);
                    }
                }else{
                    if(e.result[length-1]?.Subject.trim()===""||e.result[length-1]?.Description.trim()===""){
                        Alert("error","Please fill both the title and description fields and ensure they are not empty.")
                        await getEvent(getUser.user, dispatch, APP_NAME);
                        dispatch(isLoaded());
                    }else{
                        Alert("error","Please select Attendees")
                        await getEvent(getUser.user, dispatch, APP_NAME);
                        dispatch(isLoaded());
                    }
                }
            }else{
                if(e.result[length-1].Id!==undefined){
                    Alert("warning","Please select a date and time that are not in the past")
                    dispatch(isLoaded());
                    await getEvent(getUser.user, dispatch, APP_NAME);
                }
            }
        } else if (scheduleObj.current.currentAction === "EditOccurrence") {
            try {
                // eslint-disable-next-line
                var response = await API.post("calendar.php", {
                    urlBase: urlBase,
                    addEvent: e.result[length - 1],
                    corp_code: getUser.user.corp,
                    data: data,
                    action: scheduleObj.current.currentAction,
                    projectId: getUser.user.projectId,
                    fullName: getUser.user.fullName,
                    userName: getUser.user.userName,
                    url: Client_URL,
                    appName: APP_NAME,
                }, {}, false);
                if (response.status === "True") {
                }
            } catch (error) {
                console.log(error)
                Alert('error', error.message);
            }
        } else if (scheduleObj.current.currentAction === 'EditFollowingEvents') {
            try {
                // eslint-disable-next-line
                var response = await API.post("calendar.php", {
                    urlBase: urlBase,
                    data: data,
                    addEvent: e.result[length - 1],
                    current: scheduleObj.current.activeEventData.event,
                    action: scheduleObj.current.currentAction,
                    corp_code: getUser.user.corp,
                    projectId: getUser.user.projectId,
                    fullName: getUser.user.fullName,
                    userName: getUser.user.userName,
                    url: Client_URL,
                    appName: APP_NAME,
                }, {}, false);
            } catch (error) {
                console.log(error)
                Alert('error', error.message);
            }
        } else {
            const prevObj=scheduleObj.current.activeEventData.event
            const object=data?.filter(item=>item.Id===prevObj?.Id)
            let updateObj
            let eventId
            let assignee
            if (object[0]?.Id!==undefined){
                updateObj=object[0]
                eventId=object[0]?.Id
            }
            let validate
            if(updateObj!==undefined){
                validate=await validateDate(updateObj.EndTime)
            }
            if (prevObj?.emp_id===getUser.user.empId&&validate){
                const addMembers=updateObj?.Members?.filter(item=>!prevObj?.Members.includes(item))
                const deleteMembers=prevObj?.Members?.filter(item=>!updateObj?.Members.includes(item))
                if(prevObj?.story_type==="group"||prevObj?.event_type==="group"){
                    if(addMembers?.length>0 || deleteMembers?.length>0){
                        Alert("warning","This is a Group Event. You cannot add or remove users for this event.")
                        await getEvent(getUser.user, dispatch, APP_NAME);
                    }else{
                        await modifyEvent(updateObj,prevObj)
                    }
                }else{
                    if(deleteMembers?.length>0){
                        await deleteMembers.forEach((item)=>{
                            const user=employeeData.filter((userList)=>userList.email===item)
                            var response=API.post("calendar.php", {
                                urlBase: urlBase,
                                data: data,
                                action: "RemoveEmpEventTasks",
                                assigned_to:user[0].id,
                                corp_code: getUser.user.corp,
                                state: scheduleObj.current.currentAction,
                                current: scheduleObj.current.activeEventData.event,
                                projectId: getUser.user.projectId,
                                fullName: getUser.user.fullName,
                                userName: getUser.user.userName,
                                url: Client_URL,
                                appName: APP_NAME,
                            }, {}, false);
                            response.then(result=>{
                                if(result.status==="True"&&result?.task_details?.length>0){
                                    const deleteTask=result?.task_details?.filter(task=>task.assigned_to===user[0].id)
                                    const message = `${MAINTASK} is completed`
                                    addCommentUserStory(dispatch,getUser.user,deleteTask[0].story_id,message,"11");
                                }
                            })
                        })
                    }
                    if (addMembers?.length>0&&updateObj.isGenerate){
                        if(!(addMembers.every(item =>isNaN(item)))){
                            Alert("error","Please select only users")
                            dispatch(isLoaded());
                            await getEvent(getUser.user, dispatch, APP_NAME);
                        }else{
                            const details={
                                Subject:updateObj.Subject,
                                Description:updateObj.Description,
                                StartTime:updateObj?.StartTime,
                                Members:addMembers,
                            }
                            await modifyEvent(updateObj,prevObj)
                            await createTask(details,assignee,eventId)
                            dispatch(isLoaded());
                        }
                    }else{
                        await modifyEvent(updateObj,prevObj)
                    }
                }
                
                dispatch(isLoaded());
            }else{
                if(!validate&&prevObj?.emp_id!==undefined){
                    Alert("warning","Please select a date and time that are not in the past")
                    await getEvent(getUser.user, dispatch, APP_NAME);
                }
                else if(prevObj?.emp_id!==undefined){
                    Alert("warning","You are not authorized to modify this event")
                    await getEvent(getUser.user, dispatch, APP_NAME);
                }
                dispatch(isLoaded());
            }
            dispatch(isLoaded());
        }
    }
    // for adding extra field to default editor
    const editorTemplate = async(args) => {
        if (args.type === 'Editor') {
            let meetingUrl=""
            if(state.employees.length>0){
                const employe=state.employees.filter(emp=>emp.id===getUser.user.empId)
                meetingUrl=await updateTaskMeetingUrl(employe)
            }
            if (!args.element.querySelector('.custom-field-row')) {
                let row = createElement('div', { className: 'custom-field-row' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container' });
                let inputEle = createElement('input', {
                    className: 'e-field', attrs: { name: 'EventStatus' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
                let drowDownList = new DropDownList({
                    dataSource: [
                        { text: 'Active', value: 'Active' },
                        { text: 'Cancel', value: 'Cancel' }
                    ],
                    fields: { text: 'text', value: 'value' },
                    value: args.data.EventStatus ? args.data.EventStatus : "Active",
                    floatLabelType: 'Always', placeholder: 'Event Status'
                });
                drowDownList.appendTo(inputEle);
                inputEle.setAttribute('name', 'EventStatus');

                //add checkbox
                let generateTaskContainer = createElement('div', { className: 'custom-field-container' });
                generateTaskContainer.classList.add("generate-container");
                generateTaskContainer.style.marginTop="5px";
                let checkbox = createElement('input', {
                    className: 'e-field', attrs: { type: 'checkbox', id: 'generateTask',name:'isGenerate', checked: true }
                });
                checkbox.style.marginRight="5px";
                checkbox.style.height="18px";
                checkbox.style.width="18px";
                checkbox.style.cursor="pointer"
                generateTaskContainer.appendChild(checkbox);
                let label = createElement('label', {
                    innerHTML: 'Generate Task', attrs: { for: 'generateTask' }
                });
                label.style.marginTop="10px";
                label.style.color="blue";
                generateTaskContainer.appendChild(label);
                row.appendChild(generateTaskContainer);

                //add attendees
                let container2 = createElement('div', { className: 'custom-field-container' });
                row.appendChild(container2);
                container2.style.marginBottom="20px";
                let inputField2 = createElement('input', {
                    className: 'e-field', attrs: { name: 'Members' }
                });
                container2.appendChild(inputField2);
                let drowDownList2 = new MultiSelect({
                    dataSource: combinedEmpArray,
                    fields: { text: 'name', value: 'email' },
                    value: args.data.Members ? args.data.Members : "",
                    floatLabelType: 'Always', placeholder: 'Attendees',
                    mode: 'Box',
                    allowCustomValue: false, // Adjust as per your requirements
                    showClearButton: true,
                });
                drowDownList2.appendTo(inputField2);
                inputField2.setAttribute('name', 'Members');

                //location field
                let locationInput = args.element.querySelector('input[name="Location"]');
                locationInput.style.color="blue"
                locationInput.value = args.data.Location?args.data.Location: meetingUrl; 
                locationInput.style.cursor="pointer"
                locationInput.readOnly = true;
                // locationInput.addEventListener('click', function() {
                //     if(meetingUrl.trim()!==""){
                //         window.open(meetingUrl, '_blank');
                //     }
                // })
            }else {
                // If custom fields are already added, update their values or states
                let dropdownStatus = args.element.querySelector('.e-field[name="EventStatus"]');
                if (dropdownStatus) {
                        dropdownStatus.value= args.data.EventStatus ? args.data.EventStatus : "Active"
                }

                let checkboxGenerate = args.element.querySelector('.e-field[name="isGenerate"]');
                if (checkboxGenerate) {
                    checkboxGenerate.checked = true; // Ensure checkbox is checked
                }

                let locationInput = args.element.querySelector('input[name="Location"]');
                if(locationInput){
                    locationInput.value = args.data.Location?args.data.Location: meetingUrl;
                }
            }
        }
    }
    // for managing color for cart
    const onEventRendered = (args) => {
        if (args.data.IsAllDay === '1') {
            args.data.CategoryColor = '#1aaa55'
        } else if (args.data.EventStatus === "Cancel" && args.data.IsAllDay === '') {
            args.data.CategoryColor = '#F57F17'
            args.data.TextLine = 'line-through'
            args.data.LineColor = 'black'
        } else {
            args.data.CategoryColor = '#357CD2'
        }
        applyCategoryColor(args, scheduleObj.currentView);
    }
    const selectSprint = (selectedOption) => {
        getToDo(dispatch, getUser.user, selectedOption.moduleId);
    }

    // eslint-disable-next-line 
    const events = [...state.events].filter(val=>{
        if (new Date(val.EndTime) >= new Date()) {
            return val
        }
    })

    // while searching any event
    const globalSearch = (args) => {
        let searchString = args.target.value;
        if (searchString !== '') {
            new DataManager(scheduleObj.current.eventsData).executeQuery(new Query().search(searchString, ['Subject', 'Location', 'Description'], null, true, true)).then((e) => {
                if (e.result.length > 0) {
                    showSearchEvents('show', e.result);
                }
                else {
                    showSearchEvents('hide');
                }
            });
        }
        else {
            showSearchEvents('hide');
        }
    }
    // for display searched results
    const showSearchEvents = (type, data) => {
        if (type === 'show') {
            if (document.getElementById('grid').classList.contains('e-grid')) {
                let gridObj = document.querySelector('#grid').ej2_instances[0];
                gridObj.dataSource = data;
                gridObj.dataBind();
            }
            else {
                let gridObj = new GridComponent({
                    dataSource: data,
                    height: 505,
                    width: 'auto',
                    columns: [
                        { field: 'Subject', headerText: 'Subject', width: 120 },
                        { field: 'Location', headerText: 'Location', width: 120 },
                        { field: 'StartTime', headerText: 'StartTime', width: 120, format: { type: 'dateTime', format: 'M/d/y hh:mm a' } },
                        { field: 'EndTime', headerText: 'EndTime', width: 120, format: { type: 'dateTime', format: 'M/d/y hh:mm a' } },
                    ]
                });
                gridObj.appendTo(document.querySelector('#grid'));
                // scheduleObj.element.style.display = 'none';
            }
        }
        else {
            let gridObj = document.querySelector('#grid').ej2_instances;
            if (gridObj && gridObj.length > 0 && !gridObj[0].isDestroyed) {
                gridObj[0].destroy();
            }
            // scheduleObj.element.style.display = 'block';
        }
    }
    return (

        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <AdminSideBar />
                <div className="main-panel" >
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row ">
                                        <div className="d-flex justify-content-between col-12 row m-1 ">
                                            <h2 className="card-title calender-ml" style={{ paddingTop: 20, paddingLeft: 20 }}>CALENDAR</h2>

                                            {/* <label className="p-2" >Sprints</label> */}

                                            <div className="d-flex justify-content-end mt-2 tnb">
                                                <div className='row'>
                                                    <div className="cal-ml " style={{ width: 250, marginRight: 10 }}>

                                                        {/* for selecting data sprint vise  */}
                                                        <Select
                                                            className="form-control calender-w "
                                                            style={{ width: 10, boarderRadius: 2, }}
                                                            // placeholder="Select Active Points"
                                                            maxMenuHeight={130}
                                                            placeholder={state.sprint.moduleDesc}
                                                            value={sprints.value}
                                                            onChange={(selectedOption) => {
                                                                selectSprint(selectedOption)
                                                            }
                                                            }
                                                            options={sprints}
                                                        />
                                                    </div>
                                                    <div className="input-group-prepend cal-w" id='input_block' style={{ marginTop: 5, display: 'flex', justifyContent: 'center' }}>
    <h1 className="text" style={{ color: 'black', marginTop: '8px', fontSize: '13px' }}>Search:</h1>
    <input type="text"  className="form-control overflow-hidden mr-sm-2" size='50' style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid', borderLeft: '2px solid black', borderRight: '2px solid black', width: 250, height: '35px' }}
        onKeyUp={(args) => globalSearch(args)} />
</div>
                                                </div> 
                                            </div>
                                        </div>

                                        {state.isLoading||state.fetching ? <RootLoader /> :
                                            <div>
                                                <div id="grid"></div>
                                                {/* all UI for calendar include in ScheduleComponent  */}
                                                <ScheduleComponent
                                                    height='700px'
                                                    ref={scheduleObj}
                                                    eventSettings={{ dataSource: events, fields: fields, editFollowingEvents: true }}
                                                    dataBinding={handleData}
                                                    popupOpen={editorTemplate}
                                                    group={{
                                                        allowGroupEdit: true
                                                        // for show members list and remove multiple meet column for multiple people
                                                        // , resources: ['Conferences'] 
                                                    }}
                                                    eventRendered={onEventRendered}
                                                >
                                                    {/* for adding Members */}
                                                    {/* <ResourcesDirective>
                                                        <ResourceDirective field='Members' title='Attendees' name='Conferences' allowMultiple={true}
                                                            //   dataSource={state.employees}
                                                            dataSource={combinedEmpArray}
                                                            textField='name' idField='email'>
                                                        </ResourceDirective>
                                                    </ResourcesDirective> */}


                                                    <Inject
                                                        services={[Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize]} />
                                                </ScheduleComponent>
                                            </div>
                                        }
                                        {/* } */}
                                        {/* <div id="grid"></div> */}



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    );
}

export default Calender;