import React, { useEffect, useReducer, useState } from "react";
import $ from 'jquery';
import { projectReducer, initialState } from "./projectReducer";
import { useSelector } from "react-redux";
import RootLoader from "../Loader/RootLoader";
import ChatBox from "../Chat";
import SubTaskVerify from "../TaskVerify/subtaskVerify";
import ModifySubTask from "../SubTaskModals/modifySubtask";
import SubTaskDelete from "../SubTaskModals/deleteSubTask";
import MainTaskInfo from "../TasksModals/mainTaskInfo";
import ChangeStatus from "../../UserModule/Backlogs/changeStatus";
import { useWebProperties } from "../webProperties";
import { getSubTasks } from "./Services/getSubTasks";
import { getSubStringId } from "../SubStringConvert";
import MainTaskChatBox from "../ChatWorkflow";
import TaskInfo from "../../UserModule/UserDashboard/taskInfo";
import RoadBlock from "../RoadBlock";

export default function ProjectSubTasks(props) {
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(projectReducer, initialState)
    const [open, setOpen] = useState({ status: false, action: "", index: 0 })
    const [taskInfo, setTaskInfo] = useState();
    const { SUBTASKTITLE, EDIT, ACTION, DELETE, CHANGE_STATUS, MAINTASK, MAINTASKNAME, SUBTASK, COMMENTS,
        MAINTASK_DESCRIPTION, ASSIGNED_BY, ASSIGNED_DATE, ACTION_ICON, VIEW_DETAILS, ASSIGNED_TO, CHAT, role_array, SCRUM_MASTER, PRODUCT_OWNER } = useWebProperties();
    useEffect(() => {
        getSubTasks(dispatch, getUser.user, props.data.id);
        // getTaskMessages(dispatch, getUser.user);
        // eslint-disable-next-line
    }, [props.data])
    useEffect(() => {
        if (state.manageSubTasks.length >= 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                    "aaSorting": [[0, "desc"]]
                })
            })
        }
        //  eslint-disable-next-line 
    }, [state.manageSubTasks])
    const handleOpen = (action, index, sno) => {
        setOpen({ status: true, action: action, index: index });
        var info;
        if (action === "Verify") {
            info = {
                mainTaskId: index.story_id,
                subTaskId: index.task_id,
                taskTitle: index.task_title,
                action: action
            }
        } else if (action === "Delete") {
            info = {
                mainTaskId: index.story_id,
                subTaskId: index.task_id,
                taskTitle: index.task_title,
                taskDesc: index.task_desc,
                us_id: index.us_id,
                action: action,
                t_id: index.t_id
            }
        }
        else if (action === "taskInfo") {
            info = {
                taskTitle: index.tasktitle,
                task: index.task_title,
                subTaskDesc: index.task_desc,
                action: action,
                assignedBy: index.assignedby,
                assignedTo: index.assignedto,
                targetDate: index.target_date,
                acceptanceCriteria: index.acceptance_criteria,
                priorityLevel: index.priority,
                userDetails: index.assignedto,
                id: index.assigned_to,
                us_id: index.us_id,
                t_id: index.t_id,
            }
        }
        else if (action === "subtask_changeStatus" || action === "taskCommentInfo") {
            info = {
                us_id: index.us_id,
                id: index.task_id,
                t_id: index.t_id,
                title: index.task_title,
                action: action,
                taskId: index.story_id,
                subTaskId: index.task_id,
                activeStatus: index.active_status,
                completeStatus: index.status
            }
        }
        else if (action === "modify" || action === "reassign") {
            info = {
                mainTaskId: index.story_id,
                subTaskId: index.task_id,
                taskTitle: index.task_title,
                taskDesc: index.task_desc,
                estimatedHours: index.estimatedHours,
                action: action,
                ideaId: props.data.id,
                acceptanceCriteria: index.acceptance_criteria,
                priorityLevel: index.priority,
                targetDate: index.target_date,
                userDetails: index.assignedto,
                id: index.assigned_to,
                us_id: index.us_id,
                t_id: index.t_id,
            }

        } else if (action === 'subtask_roadblock') {
            info = {
                mainTaskId: index.story_id,
                subTaskId: index.task_id,
                action: action,
                priority: index.priority,
                message: `${SUBTASK} is blocked`
            }
        } else {
            info = {
                action: action,
                id: index.task_id,
                sno: sno
            }
        }
        setTaskInfo(info)
    };

    const handlChatOpen = (action, us_id, subtask_id, sno) => {
        var info
        setOpen({ status: true, action: action });
        if (action === 'workflow_task') {
            info = { id: us_id, subtask_id: subtask_id, action: action, sno: sno }
        }
        setTaskInfo(info)
    }

    const handleClose = () => {
        setOpen({ status: false, index: 0 });
        getSubTasks(props.data.dispatch, getUser.user, props.data.id);//solved node child error
        // getTaskMessages(dispatch, getUser.user);
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });

    }

    const getMessagesCount = (id, subtask_id, msg, empId) => {
        const msgCount = 0
        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={(event) => handlChatOpen("workflow_task", id, subtask_id, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold" }}>{msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={(event) => handlChatOpen("workflow_task", id, subtask_id, msgCount)} />
                        </div>
                }
            </i>
        )
    }

    const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);


    // console.log(state.manageSubTasks)
    return <div className="table-responsive arc">
        {state.isLoading ? <RootLoader /> :
            <table
                search="true"
                id="example" className="table table-striped table-bordered rwd-table"
                styel={{ width: "100%", overflow: 'auto' }}
                data-pagination="true"
            >
                <thead style={{ backgroundColor: '#F4FAF7' }}>
                    <tr>
                        <th style={{ display: 'none' }}>task id</th>
                        <th>{SUBTASKTITLE}</th>
                        {/* <th>{SUBTASK_DESCRIPTION}</th>
                        <th>{TARGET_DATE}</th>
                        <th>{ASSIGNED_TO}</th>
                        <th>{ASSIGNED_BY}</th>
                        <th>{ASSIGNED_ON}</th>
                        <th>{SUBTASK_PROGRESS}</th> */}
                        {/* <th>Time Left</th> */}
                        {/* <th>{STATUS}</th> */}
                        {/* {(getUser.user.role === "Scrum Master" || getUser.user.role === "Product Owner") ? <th>Verify</th> : null}
                        <th>Action</th> */}
                        <th>{MAINTASK}</th>
                        <th>{CHAT}</th>
                        <th>{COMMENTS}</th>
                        <th>{ACTION}</th>
                    </tr>
                </thead>
                <tbody>
                    {state.manageSubTasks.length > 0 ? state.manageSubTasks.map((tasks, index) => {
                        return (
                            <tr key={index}>
                                <td style={{ display: 'none' }}>{tasks.t_id}</td>
                                <td onClick={(event) => handleOpen("taskInfo", tasks)} style={{ width: '400px', textTransform: "capitalize", cursor: 'pointer' }} data-toggle="tooltip" data-placement="left" title={`${MAINTASKNAME}  :` + tasks.tasktitle + `\n\n${MAINTASK_DESCRIPTION}     :` + tasks.task_desc + `\n\n${ASSIGNED_TO}    :` + tasks.assignedto + `\n\n${ASSIGNED_BY}    :` + tasks.assignedby + `\n\n${ASSIGNED_DATE} :` + tasks.assigned_date}>{tasks.status === 'pending' ? <b>{getSubStringId(getUser.user.corp, 3)}{'-'}{tasks.us_id}-{tasks.t_id}-{tasks.task_title}</b> : <del> <b>{getSubStringId(getUser.user.corp, 3)}{'-'}{tasks.us_id}-{tasks.t_id}-{tasks.task_title}</b></del>}</td>
                                {/* <td style={{ width: '400px',textTransform: "capitalize" }}>{tasks.taskDesc}</td>
                                    <td style={{ width: '120px' }}>{tasks.targetDate}</td>
                                    <td style={{ textTransform: "capitalize" }}>{tasks.assignedTo}</td>
                                    <td style={{ textTransform: "capitalize" }}>{tasks.assignedBy}</td>
                                    <td style={{ width: '120px' }}>{tasks.assignedDate}</td>
                                    <td style={{ textAlign: 'center' }}>{tasks.taskStatus}%</td> */}
                                {/* {tasks.taskstatus === "pending" ? <td>{tasks.timeLeft}</td> : <td>{tasks.timeLeft}</td>} */}
                                {/* <td style={{ textAlign: 'center',textTransform: "capitalize" }}>
                                        {tasks.status === "pending" ? <span className="badge badge-pill badge-danger " style={{ width: '100px' }}>{tasks.status}</span>
                                            : <span className="badge badge-pill badge-success " style={{ width: '100px' }}>Completed</span>}
                                    </td> */}

                                {/* {(getUser.user.role === "Scrum Master" || getUser.user.role === "Product Owner") ? <td style={{ textAlign: 'center' }}>{tasks.status === "pending" ? <span > --- </span> : (getUser.user.role === "Scrum Master" || getUser.user.role === "Product Owner") ? <button className="dropdown-item badge badge-pill badge-success text-center" style={{ backgroundColor: "green" }} onClick={(event) => handleOpen("Verify", index)}>{VERIFY}</button> : <span > --- </span>}</td> : null} */}
                                {/* <td >{tasks.status === "pending" ? <button className="dropdown-item badge badge-pill badge-warning text-center" onClick={(event) => handleOpen("modify", index)}>{EDIT}</button> :
                                        <button className="dropdown-item badge badge-pill badge-warning text-center" onClick={(event) => handleOpen("reassign", index)}>{REASSIGN}</button>}</td> */}
                                <td style={{ textTransform: "capitalize" }}>{getSubStringId(getUser.user.corp, 3)}{'-'}{tasks.us_id}-{tasks.tasktitle}</td>
                                <td style={{ width: '8px', textAlign: 'center' }}>
                                    <button type="button" style={{ backgroundColor: 'transparent', border: "0", width: '5px', padding: "0", marginLeft: '15px' }} >
                                        {
                                            getMessagesCount(tasks.us_id, tasks.t_id, tasks.allMessages, getUser.user.empId)
                                        }
                                    </button>
                                </td>
                                <td style={{ width: '8px', textAlign: 'center' }}><button style={{ backgroundColor: 'transparent', border: "0", width: '5px', padding: "0" }} type="button" > <img src="images/common/comments.svg" alt="logo" style={{ width: '20px', height: '20px', borderRadius: 0 }} onClick={(event) => handleOpen("taskCommentInfo", tasks)} /></button></td>
                                <td style={{ textAlign: 'center', width: '10px' }}>
                                    <div className="dropdown show">
                                        {/* eslint-disable-next-line */}
                                        <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                            <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0' }} />
                                        </a>
                                        {
                                            tasks.status === "pending" ?
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                    <div>{/* eslint-disable-next-line */}
                                                        {accessRole ? <button className="dropdown-item badge badge-pill badge-warning text-center" onClick={(event) => handleOpen("modify", tasks)}>{EDIT}</button> : null}
                                                        {getUser.user.empId === tasks.assignedid ?
                                                            <button className="dropdown-item badge badge-pill badge-danger text-center" style={{ backgroundColor: '#ED7173', color: 'white' }} onClick={(event) => handleOpen("Delete", tasks)}>{DELETE}</button>
                                                            : null}
                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#5cb0bd", color: 'white' }} onClick={(event) => handleOpen("subtask_changeStatus", tasks)}>{CHANGE_STATUS}</button>
                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", tasks)}>{VIEW_DETAILS}</button>
                                                    </div>
                                                </div>
                                                :
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>

                                                    <div>{/* eslint-disable-next-line */}
                                                        {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "orange" }} onClick={(event) => handleOpen("reassign", tasks)}>{REASSIGN}</button> */}
                                                    </div>
                                                </div>
                                        }
                                    </div>

                                </td>

                            </tr>
                        )
                    }) : null}
                    {
                        open.action === "subtask" ? <ChatBox open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "Verify" ? <SubTaskVerify open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "modify" || open.action === "reassign" ? <ModifySubTask open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "Delete" ?
                            <SubTaskDelete open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                            /> : null
                    }
                    {
                        open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "subtask_changeStatus" ? <ChangeStatus open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {
                        open.action === "workflow_task" ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                        /> : null
                    }
                    {open.action === "subtask_roadblock" ? (
                        <RoadBlock
                            open={open.status}
                            handleClose={handleClose}
                            data={taskInfo}
                            handleModalClose={handleModalClose}
                        />
                    ) : null}
                    {open.status && open.action === "taskCommentInfo" ? (
                        <TaskInfo
                            open={open.status}
                            handleClose={handleModalClose}
                            data={taskInfo}
                            handleModalClose={handleModalClose}
                        />
                    ) : null}
                </tbody>
            </table>}
    </div>
}
